export default {
    claims: {
        name: "Nome",
        description: "Descrizione",
        APP:{
            canseebackoffice : "Puoi visualizzare il BackOffice",
            canupdateproceedings:"Puoi modificare i procedimenti",
            canassignproceedings : "Puoi assegnare i Procedimenti",
            canhaveproceedingsassigned : 'Puoi avere dei Procedimenti assegnati',
            candeletefees : "Puoi cancellare i Canoni",
            canmodifyfees : "Puoi modificare i Canoni",
            canaddfees : "Puoi aggiungere nuovi Canoni",
            candeleteconstraints : "Puoi cancellare i Vincoli",
            canmodifyconstraints : "Puoi modificare i Vincoli",
            canaddconstraints : "Puoi aggiungere nuovi Vincoli",
            canaddfixedpayments : "Puoi aggiungere nuovi Pagamenti Fissi",
            candeletefixedpayments :"Puoi cancellare i Pagamenti Fissi",
            canmodifyfixedpayments :"Puoi modificare i Pagamenti Fissi",
            canaddconfs : "Puoi aggiungere nuove Configurazioni",
            candeleteconfs :"Puoi cancellare le Configurazioni",
            canmodifyconfs :"Puoi modificare le Configurazioni",
            canaddcomunications : "Puoi inviare comunicazioni al cittadino",
            canmodifycomunications :"Puoi modificare le comunicazioni inviate al cittadino",
            canaddpayments : "Puoi inviare pagamenti al cittadino",
            canmodifypayments :"Puoi modificare i pagamenti inviati al cittadino"            
        },
        CHAT: {
            cancreate: "Puoi creare una chat",
            canedit: {
                title: "Puoi modificare il titolo",
                users: "Puoi modificare gli utenti di una chat"
            },
            enabled: "Abilita modulo",
        },
        DASHBOARD: {
            cancreate: "Puoi creare una dashboard",
            candelete: "Puoi cancellare una dashboard",
            canedit: "Puoi modificare una dashboard",
            canseeall: "Puoi vedere tutte le dashboard",
            enabled: "Abilita modulo",
        },
        DATASOURCE: {
            cancreate: "Puoi creare una sorgente dati esterna",
            canedit: "Puoi modificare una sorgente dati esterna",
            enabled: "Abilita modulo",
        },
        DYNAMICRULES: {
            enabled: "Abilita modulo",
        },
        GROUPS: {
            canaddusers: "Puoi aggiungere utenti ai gruppi",
            cancreate: "Puoi creare gruppi",
            candelete: "Puoi cancellare un gruppo",
            canedit: "Puoi modificare un gruppo",
            canremoveusers: "Puoi rimuovere un utente dal gruppo",
            canseeothergroups: "Puoi vedere tutti i gruppi",
            enabled: "Abilita modulo",
            canmanageclaims: "Puoi gestire i permessi",
            cantransferclaims: "Puoi trasferire i permessi",
            canseeclaims: "Puoi vedere i permessi"
        },
        ISSUES: {
            canadd: {
                groups: "Puoi aggiungere un gruppo di Issue",
            },
            canarchive: "Puoi archiviare issue",
            canchange: {
                assegnee: "Puoi assegnare una issue",
                group: "Puoi cambiare gruppo ad una issue",
                priority: "Puoi cambiare priorità ad una issue",
                status: "Puoi cambiare stato di un issue",
            },
            canconvert: "Puoi convertire una issue in task",
            cancreate: "Puoi creare issue",
            candelete: "Puoi cancellare issue",
            canedit: {
                customfields: "Puoi modificare i campi aggiuntivi di una issue",
                groups: "Puoi modificare i gruppi di issue",
                others: "Puoi modificare issue assegnate ad altri",
                own: "Puoi modificare issue assegnate a te o create da te",
                states: "Puoi modificare gli stati delle issue",
            },
            canextract: { report: "Puoi esportare in CSV le issue", },
            cansee: { all: "Puoi vedere tutte le issue", },
            enabled: "Abilita modulo",
        },
        TEAMS: {
            canaddusers: "Puoi aggiungere utenti ad un team",
            cancreate: "Puoi creare un team",
            candelete: "Puoi cancellare un team",
            canedit: "Puoi modificare un team",
            canremoveusers: "Puoi rimuovere utenti da un team",
            enabled: "Abilita modulo",
        },
        USERS: {
            canchangepassword: "Puoi modificare la password",
            cancreate: "Puoi creare utenti",
            candelete: "Puoi cancellare utenti",
            canedit: "Puoi modificare utenti",
            enabled: "Abilita modulo",
            canseeonlyfrommyteams: "Puoi vedere solo dal team"
        },
        WORKSPACE: {
            cancreate: "Puoi creare workspace",
            cancreateExpiring: "Puoi agggiungere una scadenza",
            candelete: "Puoi cancellare una workspace",
            canedit: "Puoi modificare una workspace",
            caneditExpiring: "Puoi modificare la scadenza",
            canseeExpiring: "Puoi vedere le workspace scadute",
            enabled: "Abilita modulo",
        },
        ALLEGATI: {
            CanCreate: "Puoi creare allegati",
            CanDelete: "Puoi cancellare allegati",
            CanRead: "Puoi visualizzare gli allegati",
        },
        ESRISERVICE: {
            CanPushMonitoraggio: "Puoi aggiungere geometrie di monitoraggio",
            CanReadEcografico: "Puoi leggere i dati dall'ecografico",
        },
        LAYERS: {
            ALL: {
                CanCreate: "Puoi aggiungere i layer",
                CanRead: "Puoi visualizzare i layer",
                CanEdit: "Puoi modificare i layer"
            }
        },
        INTEGRAZIONI: {
            CanDelete: "Puoi cancellare integrazioni",
            CanRead: "Puoi visualizzare integrazioni",
            CanRequest: "Puoi richiedere integrazioni",
            CanResponse: "Puoi rispondere alle integrazioni",
        },
        ISTANZE: {
            ALL: {
                CanRead: "Puoi visualizzare i tuoi procedimenti",
                CanReadAll: "Puoi visualizzare tutte i procedimenti",
                CanSignatureOnPurpose: "Puoi richiedere la firma dei procedimenti",
                CanSignatureRequire: "Puoi caricare il procedimento firmato",
                FinishProcedure: "Puoi concludere il procedimento ",
            },           
        },
        PAGAMENTI: {
            CanDelete: "Puoi cancellare pagamenti",
            CanRead: "Puoi visualizzare pagamenti",
            CanRequest: "Puoi richiedere pagamenti",
            CanResponse: "Puoi rispondere alle pagamenti",
        },
        COMUNICAZIONI: {
            CanDelete: "Puoi cancellare comunicazioni",
            CanRead: "Puoi visualizzare comunicazioni",
            CanRequest: "Puoi richiedere comunicazioni",
        },
        TIMELINE: {
            CanRead: "Puoi visualizzare i log delle operazioni"
        },
        ATTRIBUTIPROTOCOLLAZIONE: {
            ALL: {
                CanRead: "Puoi visualizzare gli attributi della Protocollazione",
                CanEdit: "Puoi modificare gli attributi della Protocollazione"
            }
        }
    }
}