export default {
  route: {
    dashboard: `Dashboard`
  },
  navbar: {
    logOut: `Esci`,
    dashboard: `Dashboard`,
    github: `Github`,
    theme: `Tema`,
    size: `Grandezza Globale`,
    profile: `Profilo`
  },
  common: {
    delete: 'Cancella',
    confirm: 'Conferma',
    cancel: 'Annulla',
    uploadedFiles: 'File caricati',
    inQualitadi: {
      proprietarioterreno: 'Proprietario Terreno',
      legalerappresentante: 'Legale Rappresentante',
      altri: 'Altro',
      utilizzatore: 'Utilizzatore',
      titolaredittaindividuale: 'Titolare - Ditta individuale',
      affittuario: 'Affittuario'
    },
    salvataggio: {
      success: 'Procedimento salvato con Successo',
      error: 'Errore nella presentazione del procedimento'
    }
  },
  users: {
    search: `Cerca`,
    accountLabel: `Account:`,
    createUserTitle: `Inserisci utente`,
    createUserLogin: `Login`,
    createUserLoginTip: `Inserisci Login`,
    createUserEmail: `Email`,
    createUserPhone: `Numero di telefono`,
    createUserFiscalCode: `Codice Fiscale`,
    userExistsTitle: `Attenzione`,
    userExistsBody: `Utente Esistente`,
    updateUserButton: `Aggiorna`,
    removeUserButton: `Elimina`
  },
  errorPage: {
    back: `Torna alla Home`,
    401: {
      title: `Non sei autorizzato ad accedere`,
      description: `Siamo spiacenti, l'accesso alla pagina richiesta con le sue credenziali è stato negato.`
    },
    404: {
      title: `Pagina non trovata`,
      description: `Siamo spiacenti, ma non è stato possibile trovare la pagina richiesta.`
    }
  },
  cookies: {
    cookies: 'Questo sito utilizza cookie tecnici e analytics.',
    navigazione: 'Proseguendo nella navigazione accetti l’utilizzo dei cookie.'
  },
  canoni: {
    tipoComponente: {
      fissa: 'Fissa',
      aggiuntiva: 'Aggiuntiva'
    }
  },
  pagamenti: {
    pagoPA: 'Richiedi un pagamento con PagoPA',
    manuale: 'Richiedi un pagamento manuale: si dovrà allegare la ricevuta di pagamento'
  },
  prelievi: {
    tipologia: {
      concessione: 'Concessione',
      licenzaattingimento: 'Licenza di attingimento'
    },
    concessione: {
      nuova: 'Nuova concessione',
      rinnovo: 'Rinnovo concessione'
    },
    variazioneRichiesta: {
      ordinariaconcessione: 'Concessione ordinaria',
      cambiotitolarita: 'Cambio titolarità',
      variazionesostanziale: 'Variazione sostanziale',
      variazionenonsostanziale: 'Variazione non sostanziale',
      usodiverso: 'Uso diverso',
      irriguosopra: 'Irriguo sopra i 5000 mq',
      irriguosotto: 'Irriguo sotto i 5000 mq',
      sostituzionelicenza: 'Sostituzione licenza'
    },
    stato: {
      bozza: 'Bozza',
      preistruttoria: 'Preistruttoria',
      presentata: 'Presentato',
      verifica: 'In Verifica',
      attesaintegrazione: 'In Attesa di Integrazione',
      archiviazione: 'Archiviato',
      rilascio: 'Permesso Rilasciato',
      lavorazione: 'In Lavorazione'
    },
    statoIcona: {
      bozza: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-bookmark"></use></svg>`,
      preistruttoria: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-pa"></use></svg>`,
      presentata: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-files"></use></svg>`,
      verifica: `<svg class="mx-1 icon icon-info"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-warning-circle"></use></svg>`,
      attesaintegrazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-info-circle"></use></svg>`,
      archiviazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-box"></use></svg>`,
      rilascio: `<svg class="mx-1 icon icon-success"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-check-circle"></use></svg>`,
      lavorazione: `<svg class="mx-1 icon icon-warning"><use href="/assets/lib/bootstrap-italia/dist/svg/sprites.svg#it-pencil"></use></svg>`
    },
    sistemaIrrigazione: {
      pioggiaLabel: 'SISTEMA A PIOGGIA',
      pioggiaText: `Indicare le fasce orarie per un massimo di <strong>6 ore</strong> settimanali nei giorni feriali consentiti <strong>(tranne che dalle ore 12.00 alle ore 17.00)</strong>`,
      gocciaLabel: 'SISTEMA A GOCCIA',
      gocciaText: `Indicare le fasce orarie per un massimo di <strong>12 ore</strong> settimanali nei giorni feriali`
    },
    calendario: {
      giorni: {
        lunedi: 'Lunedì',
        martedi: 'Martedì',
        mercoledi: 'Mercoledì',
        giovedi: 'Giovedì',
        venerdi: 'Venerdì',
        sabato: 'Sabato',
        domenica: 'Domenica'
      },
      title: 'Fasce orarie',
      subtitle: "Indicare le fasce orarie in cui si intende effettuare l'attingimento",
      notabene:
        " Nota bene: è vietato l'attingimento il sabato ad eccezione dei seguenti corsi d'acqua e corpi idrici sotterranei che ricadono nella fascia dei 100 metri dalla sponda degli stessi: Tevere, Campiano, Clitunno, Corno a valle della confluenza con il Sordo, Timia a valle della confluenza con il Clitunno, Nera, Sordo, Vigi, Chiascio a valle della diga di Casanova.",
      danger: 'Attenzione!',
      error: 'Il massimo delle ore settimanali è di ore:',
      maxHours: 'Massimo ore:',
      counterHours: 'Conteggio ore totali:',
      day: 'Giorno',
      from: 'Dalle ore',
      to: 'Alle ore',
      add: 'Aggiungi nuova fascia oraria'
    },
    datiCatastali: {
      comune: 'Comune',
      localita: 'Località',
      foglio: 'Foglio',
      particella: 'Particella',
      subalterno: 'Subalterno',
      add: 'Aggiungi nuovi dati catastali'
    },
    tipoUso: {
      industriale: 'Industriale',
      zootecnico: 'Zootecnico',
      piscicolturaittiogenico: 'Ittiogenico',
      accumuloinvernaleinvaso: 'Accumulo invernale invaso',
      irrigazioneareeverdi: 'Irrigazione verde',
      irrigazioneimpiantisportivi: 'Irrigazione impianti sportivi',
      irriguo: 'Irriguo',
      abbeveraggioanimalicortile: 'Abbeveraggio animali cortile',
      altro: 'Altro',
      idropotabile: 'Potabile',
      beniservizi: 'Produzione beni servizi',
      geotermico: 'Geotermico',
      forzamotrice: 'Forza motrice',
      idroelettrico: 'Idroelettrico (piccole derivazioni)',
      domestico: 'Domestico',
      irrigazioneagricola: 'Agrituristico',
      nonspecificato: 'Non specificato',
      igienico: 'Igienico',
      autolavaggio: 'Auto lavaggio',
      lavaggiomacchinari: 'Lavaggio macchinari',
      lavaggiostradepiazzaliecontrollopolveriinerti: 'Lavaggio strade/piazzali e controllo polveri inerti',
      pescasportiva: 'Pesca sportiva',
      antincendio: 'Antincendio',
      trattamentiantiparassitari: 'Trattamentianti parassitari',
      usovenatorio: 'Uso venatorio',
      forzamotriceidroelettricograndiderivazioni: 'Forza motrice idroelettrico grandi derivazioni',
      forzamotriceidroelettricopiccolederivazioni: 'Forza motrice idroelettrico piccole derivazioni',
      abbattimentopolveri: "Abbattimento polveri"
    },
    tipologiaAllegatiCorto: {
      CartaDiIdentità: 'Carta di identità',
      DocumentazioneFotografica: 'Documentazione fotografica',
      Planimetria: 'Planimetria',
      GiustificazioneNonProprietari: 'Giustificazione non proprietari',
      AutorizzazioneDelProprietario: 'Autorizzazione del proprietario',
      AutorizzazioneInvaso: 'Autorizzazione invaso',
      ParereEnteParco: 'Parere ente parco',
      SpeseIstruttoria: 'Spese istruttoria',
      RelazioneTecnica: 'Relazione tecnica ',
      Corografia: 'Corografia',
      richiestaPagamento: 'Richiesta Pagamento',
      rispostaPagamento: 'Risposta Pagamento',
      allegato: 'Allegato',
      Bollo: 'Marca da Bollo',
      ElaboratiTecnici: 'Elaborati tecnici',
      RicevutaPagoPa: 'Ricevuta PagoPA',
      SCIA: 'SCIA',
      Cartografia: 'CARTOGRAFIA - MAPPE CATASTALI',
      ComplessiIdrogeologici: 'TAVOLA N. 5',
      StatoChimico: 'TAVOLA N. 10a PTA2',
      StatoQuantitativo: 'TAVOLA N. 10b PTA2',
      Allegato7: 'ALLEGATO 7',
      IndirizziOperativi: 'Indirizzi operativi',
      AllegatoA: 'ALLEGATO A',
      Tavola14: 'TAVOLA N. 14 PTA2',
      Allegato4: 'ALLEGATO IV PARTE SECONDA',
      richiestaComunicazioni: 'Allegato Comunicazione',
      rispostaComunicazioni: 'Risposta Comunicazione',
      CartaDiIdentitaCambioTitolarita: 'Carta di identità',
      CambioTitolarita: 'Cambio di titolarità',
      ParereNatura2000: 'Parere Natura2000',
      CartaDiIdentitaVariazione: 'Documento di identità;',
      CartaTecnicaRegionale: 'Carta tecnica regionale',
      EstrattoPlanimetria: 'Planimetria catastale',
      RelazioneTecnicaGenerale: 'Relazione tecnica',
      PianteProspettiSezioni: 'Piante, prospetti, sezioni',
      RilascioLicenzaAttingimento: 'Rilascio Licenza Attingimento',
      RilascioConcessione: 'Rilascio Concessione',
      RichiestaLicenzaAttingimento: 'Richiesta Licenza Attingimento Firmata',
      RichiestaConcessione: 'Richiesta Concessione Firmata',
      AvvisoAnalogico: 'Avviso Analogico'
    },
    tipologiaAllegati: {
      CartaDiIdentità: 'Copia di un documento di identità del richiedente',
      DocumentazioneFotografica: 'Documentazione fotografica del tratto di alveo/sponda interessata dalla collocazione della pompa di attingimento',
      Planimetria: 'Planimetrie in scala 1/2000 in cui sono evidenziate le particelle interessate ed il punto di prelievo delle acque',
      GiustificazioneNonProprietari: "Per i non proprietari allegare titolo che giustifichi l'istanza (contratto di affitto, comodato o altro)",
      AutorizzazioneDelProprietario: 'Autorizzazione del proprietario dei terreni dove è collocata la condotta di adduzione, qualora diverso dal richiedente',
      AutorizzazioneInvaso: "Per coloro che utilizzano un invaso allegare l'autorizzazione alla realizzazione dello stesso",
      ParereEnteParco: "Parere favorevole all'attingimento rilasciato dall'ente parco",
      SpeseIstruttoria: 'Ricevute versamento: spese di istruttoria €. 30,00 e canone',
      RelazioneTecnica:
        "Relazione tecnica illustrativa sul tipo di insediamento, sul consumo previsto nell'arco dell'anno, sull'uso, sulle modalità di captazione dell'acqua e sull'aventuale restituzione della stessa",
      Corografia: "Corografie in scala 1/25000 in cui è evidenziata la zona di prelievo dell'acqua",
      allegato: 'Allegato',
      Bollo: 'Marca da Bollo da 16€',
      ElaboratiTecnici: 'Elaborati tecnici a firma di un professionista abilitato completi degli elementi riportati nella “Scheda A - pozzi” (relazione tecnica, relazione geologica etc.',
      RicevutaPagoPa: 'Ricevuta PagoPA del versamento delle spese di istruttoria indicate nella D.G.R. n. 1670 del 29/12/2015 (1/40 del canone)',
      SCIA: 'Copia della SCIA presentata al Comune',
      Cartografia: 'CARTOGRAFIA IGM 1:25.000 - MAPPE CATASTALI 1:2000',
      ComplessiIdrogeologici: 'TAVOLA N. 5 PTA2 - Complessi idrogeologici',
      StatoChimico: 'TAVOLA N. 10a PTA2 - Stato chimico corpi idrici (aggiornamento con D.G.R. n. 1311 del 13.12.2023)',
      StatoQuantitativo: 'TAVOLA N. 10b PTA2 - Stato quantitativo corpi idrici',
      Allegato7: 'ALLEGATO 7 D.G.R. n. 925/2003',
      IndirizziOperativi: 'ALLEGATO 7 - APPENDICE ALLA DIRETTIVA “Indirizzi operativi”',
      AllegatoA: 'ALLEGATO A - D.G.R. N. 495/2023',
      Tavola14: 'TAVOLA N. 14 PTA2 - Aree di salvaguardia',
      Allegato4: 'ALLEGATO IV PARTE SECONDA D.Lgs 152/2006',
      CartaDiIdentitaCambioTitolarita: 'Fotocopia dei documenti di identità (del dichiarante e del Legale Rappresentante della Società subentrante); ',
      CambioTitolarita:
        'Documentazione comprovante il cambio di titolarità (copia conforme dell’atto di compravendita, affitto, fusione, dichiarazione di successione, donazione, ecc. oppure dichiarazione sostitutiva di certificazione o di atto di notorietà ai sensi degli artt 46 e 47 del DPR 445/2000)',
      ParereNatura2000: `Parere favorevole all’attingimento ai sensi della direttiva 92/43/CEE, del DPR 357/97 e della LR 1/2015 rilasciato dal Servizio Foreste, montagna, sistemi naturalistici e Faunistica-venatoria della Regione Umbria`,
      CartaDiIdentitaVariazione: 'Fotocopia di un documento di identità;',
      CartaTecnicaRegionale: 'Estratto della carta tecnica regionale (CTR 1:10.000) con la localizzazione della/e opera/e di variante;',
      EstrattoPlanimetria: 'Estratto della planimetria catastale (1:2.000) con la localizzazione della/e opera/e di variante;',
      RelazioneTecnicaGenerale: 'Relazione tecnica generale descrittiva della/e opera/e di variante a firma di un tecnico iscritto al relativo Albo o Ordine Professionale;',
      PianteProspettiSezioni:
        'Piante, prospetti, sezioni e particolari costruttivi in scala 1:100, o in scala adeguata, delle opere relative alla variante alla concessione, a firma di un tecnico abilitato;',
      RilascioLicenzaAttingimento: 'Rilascio Licenza Attingimento',
      RilascioConcessione: 'Rilascio Concessione',
      RichiestaLicenzaAttingimento: 'Richiesta Licenza Attingimento Firmata',
      RichiestaConcessione: 'Richiesta Concessione Firmata',
      AvvisoAnalogico: 'Avviso Analogico'
    },
    tipologiaAllegatiConcessioniCorto: {
      common: {
        CartaDiIdentità: 'Carta Di Identità',        
      },
      superficiali: {
        SchedaFiltro: "Scheda filtro",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Relazione Tecnica",
        ElaboratiGrafici: "Elaborati Grafici",
        CalcoloSommario: "Calcolo sommario della spesa e piano finanziario",
        DichiarazioneProprieta: "Dichiarazione di proprietà dei terreni",
        DichiarazioneConformitaPesca: "Dichiarazione di conformità per impianti ittiogenici e pesca sportiva",
        DichiarazioneImpedimento: "Dichiarazione di impedimento all’uso del servizio di rete pubblica",
        DichiarazioneAssenso: "Dichiarazione di assenso del proprietario",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare",
      },
      superficialiIdroelettriche: {
        SchedaFiltro: "Scheda filtro",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Relazione tecnica",
        DisegniTecnici: "Disegni tecnici",
        Misure: "Misure, dimensionamento e verifiche",
        ElaboratiCartografici: "Elaborati cartografici",
        DichiarazioneProprieta: "Dichiarazione di proprietà dei terreni",
        ComputoMetrico: "Computo metrico estimativo e piano finanziario",
        DichiarazioneImpedimento: "Dichiarazione di impedimento all’uso del servizio di rete pubblica",
        DichiarazioneAssenso: "Dichiarazione di assenso del proprietario",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare", 
      },
      sorgenti: {
        SchedaFiltro: "Scheda filtro",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Relazione tecnica ",
        RelazioneGeologica: "Relazione geologica e idrogeologica",
        ElaboratiGrafici: "Elaborati grafici",
        DichiarazioneProprieta: "Dichiarazione di proprietà dei terreni",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare",
        DichiarazioneImpedimento: "Dichiarazione di impedimento all’uso del servizio di rete pubblica",
        Idoneita: "Idoneità all’uso potabile",
        DichiarazioneConformitaPesca: "Dichiarazione di conformità per impianti ittiogenici e pesca sportiva",
        DichiarazioneAssenso: "Dichiarazione di assenso del proprietario",
      },
      sorgentiIdroelettriche: {
        SchedaFiltro: "Scheda filtro",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Relazione tecnica",
        RelazioneGeologica: "Relazione geologica e idrogeologica",
        DisegniTecnici: "Disegni tecnici",
        ElaboratiCartografici: "Elaborati cartografici",
        Misure: "Misure, dimensionamento e verifiche",
        ComputoMetrico: "Computo metrico estimativo e piano finanziario",
        DichiarazioneProprieta: "Dichiarazione di proprietà dei terreni",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare", 
        DichiarazioneAssenso: "Dichiarazione di assenso del proprietario",  
      },
      sotterranee: {
        RelazioneGeologica: "Relazione geologica e idrogeologica",
        RelazioneTecnica: "Relazione tecnica illustrativa",
        ElaboratiGrafici: "Elaborati grafici e visura catastale",
        VisuraCamerale: "Visura Camerale",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate",
        DichiarazioneImpedimento: "Dichiarazione di impedimento all’uso del servizio di rete pubblica",
        DichiarazioneProprieta: "Dichiarazione o atto di proprietà",
      },
      // SCHEDA_A_Pozzi: {
      //   A_RelazioneGeologicaIdrologica: 'Assetto idrogeologico e fornisce indicazioni sugli aspetti geologici e geomorfologici',
      //   A1_LineamentiGeomorfologici: 'Lineamenti geomorfologici, geologici e d’uso del suolo',
      //   A2_StudioIdrologico: 'Lo studio idrogeologico generale sull’assetto litostratigrafico e sulle caratteristiche delle falde',
      //   A3_SchemaIdrologico: 'Lo schema idrogeologico di massima del complesso idrogeologico oggetto di captazione',
      //   A4_CompatibilitaCaptazioni: 'Le considerazioni sulla compatibilità delle captazioni previste con il bilancio d’acqua e con l’assetto idrogeologico',
      //   A5_UsiRisorsa: 'L’uso o gli usi cui la risorsa è destinata',
      //   A6_StratigrafiaTerreni: 'La stratigrafia dei terreni attraversati, il livello statico della falda',
      //   A7_ProvaPompaggio: 'Le modalità di effettuazione ed i risultati di una prova di pompaggio',
      //   A8_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   A81_InquadramentoCorografico: 'L’inquadramento corografico',
      //   A82_CartaGeologica: 'La carta geologica',
      //   A83_CartaIdrogeologica: 'La carta idrogeologica',
      //   A84_SezioniGelogiche: 'Le sezioni geologiche ed idrogeologiche',
      //   B_RelazioneTecnica: 'La relazione tecnica dovrà descrivere lo stato dell’opera di captazione',
      //   B1_FabbisognoAcqua: 'Il fabbisogno quantitativo d’acqua',
      //   B11_FabbisognoLordo: 'Fabbisogno lordo delle colture agrarie',
      //   B12_ConsistenzaAllevamento: 'Tipo e la consistenza dell’allevamento',
      //   B13_UsoIgienicoAgrituristico: 'Informazioni sull’utilizzo effettivo della risorsa e sui quantitativi occorrenti per ciascuno scopo',
      //   B14_UsoIgienicoSanitario: 'informazioni sulla consistenza dell’utenza e sulla dotazione pro capite',
      //   B15_UsoConsumoUmano: 'Effettiva necessità quantitativa sulla base della popolazione servita',
      //   B16_UsoBeniServizi: 'Natura del processo produttivo e le relative quantità d’acqua impiegata',
      //   B17_AltriUsi: 'Congruità dei volumi di prelievo richiesti in relazione agli utilizzi previsti',
      //   B2_DatiCatastaliRestituzione: 'I dati catastali relativi all’ubicazione dell’opera di presa e di eventuale restituzione',
      //   B3_DatiCatastaliIrriguo: 'I dati catastali relativi alla superficie da irrigare (nel caso di uso irriguo)',
      //   B4_Coordinate: 'Le coordinate Gauss Boaga',
      //   B5_Diametriperforazione: 'Il metodo e i diametri di perforazione',
      //   B6_Tubatura: 'Il tipo e le caratteristiche della tubatura di rivestimento',
      //   B7_DrenaggiFiltri: 'Il tipo di drenaggi e di filtri',
      //   B8_Fenestratura: 'Il tipo di fenestratura',
      //   B9_ProtezioneFalda: 'Il metodo di protezione e separazione della falda',
      //   B10_SviluppoPozzo: 'Il metodo di sviluppo del pozzo',
      //   B11_CaratteristichePompa: 'Le caratteristiche della pompa che si intende utilizzare',
      //   B12_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   B121_Cartografia: 'Inquadramento generale su base cartografica',
      //   B122_Corografia: 'Corografia',
      //   B123_PlanimetriaCatastale: 'Planimetria catastale',
      //   B124_PercorsiAcque: 'Piante, prospetti, sezioni e particolari',
      //   B125_SchemaStratigrafico: 'Schema stratigrafico e costruttivo del pozzo',
      //   C_VisuraCatastale: 'Visura catastale dei terreni su cui ricadono i pozzi o Atto di proprietà',
      //   D_IscrizioneCameraCommercio: 'Dichiarazione sostitutiva del certificato di Iscrizione alla Camera di Commercio',
      //   E_DocumentazioneFotografica: 'Documentazione fotografica referenziata',
      //   F_DocumentoRiconoscimento: 'Copia del documento di riconoscimento in corso di validità del richiedente',
      //   G_AttoAutorizzativo: "Copia dell'atto autorizzativo di realizzazione delle opere",
      //   H_DichiarazioneConsorziIrrigui: "Dichiarazione secondo cui l'azienda non è servita da consorzi irrigui, nè ha altre fonti",
      //   I_AttoCompravendita: 'Atto di compravendita',
      //   J_PagamentoCanone: 'Eventuale copia del pagamento del canone annuo',
      //   K_AdempimentiPrevisti: 'Adempimenti previsti dalle vigenti normative'
      // },
      // SCHEDA_B_Sorgenti: {
      //   A_RelazioneGeologica: 'Lo studio dovrà contenere almeno quanto previsto ai punti successivi',
      //   A1_InquadramentoArea: 'Inquadramento dell’area',
      //   A2_IdentificazioneCartografica: 'Identificazione cartografica del bacino di alimentazione della sorgente',
      //   A3_ApportiMeteorici: 'Stima degli apporti meteorici che il bacino di alimentazione della sorgente',
      //   A4_RegimePortata: 'Regime di portata della sorgente',
      //   A5_Portate: 'Portate naturali minima e massima della sorgente',
      //   A6_Quota: 'Quota del punto di emergenza',
      //   A7_TipoSorgente: 'Indicazione del tipo di sorgente in base alle caratteristiche di emergenza',
      //   A8_EmergenzeCaptazioni: 'Indicazione e localizzazione di altre emergenze e/o captazioni nell’area',
      //   A9_UtilizzoSuolo: 'Descrizione dell’utilizzo del suolo',
      //   A10_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   A101_Corografia: 'Inquadramento corografico',
      //   A102_CartaGeologica: 'Carta geologica',
      //   A103_CartaIdrogeologica: 'Carta idrogeologica',
      //   A104_SezioniGeologiche: 'Sezioni geologiche ed idrogeologiche',
      //   B_RelazioneTenica: 'Opere di captazione',
      //   B1_Fabbisogno: 'Il fabbisogno del quantitativo d’acqua',
      //   B2_QuantitativiRichiesti: 'Quantitativi richiesti in concessione',
      //   B21_UsoIrriguoFabbisognoLordo: 'Fabbisogno lordo delle colture agrarie',
      //   B22_UsoZootecnico: 'Tipo e la consistenza dell’allevamento',
      //   B23_UsoAgrituristico: 'Utilizzo effettivo della risorsa e sui quantitativi occorrenti per ciascuno scopo',
      //   B24_UsoPotabile: 'Effettiva necessità quantitativa sulla base della popolazione servita',
      //   B25_UsoBeniServizi: 'Natura del processo produttivo',
      //   B26_AltriUsi: 'Congruità dei volumi di prelievo richiesti in relazione agli utilizzi previsti',
      //   B27_Dispositivi: 'Descrizione dei dispositivi per la misurazione delle portate e dei volumi derivati ed eventualmente di quelli restituiti',
      //   B3_DatiCatastaliOperePresa: 'I dati catastali relativi all’ubicazione dell’opera di presa e di eventuale restituzione',
      //   B4_DatiCatastaliSup: 'dati catastali relativi alla superficie da irrigare (nel caso di uso irriguo)',
      //   B5_Coordinate: 'Le coordinate Gauss Boaga del punto di presa e di eventuale restituzione',
      //   B6_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   B61_InquadramentoCartografico: 'Inquadramento generale su base cartografica',
      //   B62_Corografia: 'Corografia su CTR',
      //   B63_Planimetria: 'Planimetria catastale',
      //   B64_Sezioni: 'Piante, prospetti, sezioni e particolari',
      //   C_Proprieta: 'Certificazione attestante la proprietà o la disponibilità dei terreni',
      //   D_IscrizioneCameraCommercio: 'Iscrizione alla Camera di Commercio',
      //   E_DocumentazioneFotografica: 'Documentazione fotografica referenziata delle opere esistenti',
      //   F_DocumentoRiconoscimento: 'Copia del documento di riconoscimento in corso di validità del richiedente',
      //   G_AutorizzazioniEdilizie: 'Autorizzazioni edilizie delle opere esistenti e/o da realizzare',
      //   H_Fabbisogno: 'Dichiarazione fabbisogno idrico',
      //   I_AttivazioneASL: 'Documentazione attestante l’attivazione certificazione di “idoneità all’uso potabile”'
      // },
      // SCHEDA_C_Superficiali: {
      //   grandi: {
      //     A_RelazioneParticolareggiata: 'Relazione particolareggiata',
      //     A1_ModalitaDervizione: `Modalità di derivazione e caratteristiche sistema di restituzione`,
      //     A2_OpereIdrauliche: `Descrizione delle principali opere idrauliche e dei dispositivi`,
      //     A3_CaratterizzazioneIdrologica: `Caratterizzazione idrologica del corpo idrico e calcolo della portata disponibile`,
      //     B_Corografia: 'Corografia',
      //     C_PianoGenerale: 'Piano Generale',
      //     D_Profili: 'Profili',
      //     E_Disegni: 'Disegni',
      //     F_CalcoloSommario: 'Calcolo sommario',
      //     G_CartaTecnicaRegionale: 'Carta tecnica regionale',
      //     H_Planimetria: 'Planimetria',
      //     I_Dispositivi: 'Dispositivi di misurazione',
      //     J_Fattibilità: 'Fattibilità',
      //     K_FabbisognoIdrico: 'Fabbisogno idrico',
      //     L_ProprietaTerreni: 'Proprietà dei terreni',
      //     M_AssensoProprietario: 'Assenso proprietario',
      //     N_TipologiaPompe: 'Tipologia di pompe',
      //     O_QuantitativoDeterminatoIrriguo: 'Calcolo del quantitativo determinato',
      //     P_AutorizzazioniEdilizie: 'Autorizzazioni opere edilizie',
      //     Q_StruttureProtezione: 'Progetto  strutture a protezione della fauna ittica',
      //     R_ProgettazioneConforme: 'Progettazione per la realizzazione di opere concernenti ittiogenico o di pesca sportiva',
      //     S_DocumentazioneFotografica: 'Documentazione fotografica',
      //     T_AutoritaBacino: 'Scheda Autorità di Bacino del Fiume Tevere',
      //     U_DichiarazioneSostitutivaCameraCommercio: 'Certificato di Iscrizione alla camera di commercio',
      //     V_DeflussoMinimoVitale: 'Deflusso Minimo Vitale (DMV)'
      //   },
      //   piccole: {
      //     A_RelazioneParticolareggiata: `Relazione particolareggiata`,
      //     A1_ModalitaDervizione: `Modalità di derivazione e caratteristiche sistema di restituzione`,
      //     A2_OpereIdrauliche: `Descrizione delle principali opere idrauliche`,
      //     A3_CaratterizzazioneIdrologica: `Caratterizzazione idrologica del corpo idrico e portate`,
      //     B_Corografia: 'Corografia',
      //     C_PianoTopografico: 'Piano topografico',
      //     D_Profili: 'Profili longitudinali e trasversali',
      //     E_DisegniOpereArte: 'Disegni delle principali opere d’arte',
      //     F_CartaTenicaRegionale: 'Estratto della carta tecnica regionale',
      //     G_Planimetria: 'Estratto della planimetria catastale',
      //     H_Dispositivi: 'Descrizione dei dispositivi di misurazione delle portate e dei volumi',
      //     I_Fattibilita: 'Analisi di fattibilità di impianti',
      //     J_FabbisognoIdrico: 'Dichiarazione che il fabbisogno idrico',
      //     K_ProprietaTerreni: 'Dichiarazione proprietà dei terreni',
      //     L_AssensoProprietario: 'Dichiarazione del proprietario del terreno attestante l’assenso',
      //     M_TipologiaPompe: 'Tipologia di pompe che verranno installate (qualora previste)',
      //     N_QuantitativoDeterminatoIrriguo: 'Quantitativo coltura praticati',
      //     O_AutorizzazioneEdilizie: 'Autorizzazioni edilizie relative alle opere',
      //     P_StruttureProtezione: 'Progetto strutture a protezione',
      //     Q_OpereIttiogeniche: 'Progettazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva',
      //     R_DocumentazioneFotografica: 'Documentazione fotografica',
      //     S_AutoritaBacino: 'Compilazione della scheda filtro dell’Autorità di Bacino del Fiume Tevere',
      //     T_DichiarazioneSostitutivaCameraCommercio: 'Certificato di Iscrizione alla camera di commercio',
      //     U_DeflussoMinimoVitale: 'Deflusso Minimo Vitale (DMV)'
      //   }
      // },
      // SCHEDA_D_DerivazioneElettrica: {
      //   A_RelazioneTecnica: 'Relazione Tecnica che contenga almeno la descrizione di',
      //   A1_Tipologia: 'tipologia (corso d’acqua, lago, invaso) ',
      //   A2_UsoDestinazione: 'uso prevalente ed eventuali usi secondari a cui la risorsa è destinata',
      //   A3_Volumi: 'volume totale annuo di acqua prelevata (mc/anno)',
      //   A4_ModalitaCaratteristiche: 'modalità di derivazione, caratteristiche dell’opera di presa e del sistema di restituzione',
      //   A5_Opere: 'tipologia, forma, dimensioni delle opere esistenti',
      //   A6_TipologiaTurbina: 'tipologia di turbina (o di turbine) e sue caratteristiche tecniche',
      //   A7_SaltoLegale: 'salto legale H',
      //   A8_EnergiaProdotta: 'energia prodotta in Kwh nell’ “anno tipo”',
      //   A9_MeccanismiPortate: 'meccanismi di regolazione delle portate richieste',
      //   A10_DispositiviDinamici: 'tipologia e dimensioni dei dispositivi di ritenuta mobile, se presenti',
      //   A11_DispositiviDinamiciSicurezza: 'logica di comando di dispositivi di ritenuta mobile',
      //   A12_DispositiviDinamiciIdroelettrico: 'dispositivi effetti dinamici produzione idroelettrica',
      //   A13_RischioIdraulico: 'valutazioni sull’eventuale modifica del rischio idraulico',
      //   A14_OpereAcquePubbliche: 'compatibilità delle opere proposte rispetto al regime delle acque pubbliche',
      //   A15_InformazioniGeognistiche: 'informazioni geognostiche e geotecniche opera di presa,di restituzione, della centrale di produzione',
      //   A16_BacinoIdrografico: 'bacino idrografico: dati dimensionali e sue caratteristiche morfologiche',
      //   A17_IdrologicoCorpoIdrico: 'aspetti idrologico/idraulici del corpo idrico',
      //   A18_IdrologicoArea: 'aspetti idrogeologici dell’area di realizzazione delle opere',
      //   A19_SistemiProtezione: 'provvedimenti e sistemi di protezione dei locali quadri comando',
      //   A20_AccessoPedonale: 'percorso per l’accesso pedonale ai locali ',
      //   A21_OperazioniSvaso: 'eventuali operazioni di svaso',
      //   A22_VascaCarico: 'vasca di carico se presente',
      //   A23_ProtezioneCanali: 'eventuali sistemi di protezione dei canali',
      //   A24_ModalitaRestituzione: 'modalità di restituzione delle acque',
      //   A25_RealizzazioneViabilita: 'eventuale realizzazione di nuova viabilità permanente',
      //   A26_Dmv: 'DMVN= dmv rilasciato sulla base delle vigenti normative l/s',
      //   A27_RispettoDMV: 'sistema a garanzia del rispetto del DMV',
      //   A28_LunghezzaTratto: 'L1= lunghezza del tratto di alveo sotteso m',
      //   A29_LunghezzaRicurgito: 'LR =lunghezza rigurgito in regime di funzionamento nominale',
      //   A30_LunghezzaRicurgitoMassima: 'LRmax=lunghezza rigurgito in regime di funzionamento di massima portata derivabile',
      //   A31_VolumeInvasatoInvasi: 'Vi = volume invasato alla quota di regolazione in mc (per gli invasi)',
      //   A32_AltezzaSbarramento: 'hi = altezza dello sbarramento in m (per gli invasi',
      //   A33_VolumeInvasatoAcqua: 'V = volume invasato alla quota di regolazione in mc (per impianti ad acqua fluente)',
      //   A34_Compatibilita: 'compatibilità con allegati B e C del Regolamento Regionale n. 7/2011',
      //   B_Calcolazioni: 'Calcolazioni',
      //   B1_Bocche: 'dimensionamento delle bocche di presa e dei canali a giustificazione delle portate richieste',
      //   B2_CurvaPortate: 'curva di durata delle portate',
      //   B3_DimensioneTurbina: 'dimensionamento di massima della turbina/e',
      //   B4_VerificheIdrauliche: 'verifiche idrauliche in regime di moto permanente estese',
      //   B41_PortataMediaDerivata: 'esercizio alla portata media derivata',
      //   B42_Piena50: 'evento di piena con tempo di ritorno cinquantennale',
      //   B43_Piena200: 'evento di piena con tempo di ritorno duecentennale',
      //   B44_Piena200Avaria: 'evento di piena con tempo di ritorno duecentennale',
      //   C_ElaboratiCartografici: 'Elaborati cartografici',
      //   C1_Corografia: 'corografia IGM 1:25000 con inquadramento',
      //   C2_CartaTecnica: 'carta Tecnica Regionale 1:10000 con inquadramento',
      //   C3_PlanimetriaCatastale: 'planimetria su base catastale ',
      //   C4_Sezioni: 'profilo longitudinale e sezioni trasversali ',
      //   C5_Planimetrie: 'planimetrie, profili longitudinali e sezioni trasversali ',
      //   C6_Profili: 'profilo longitudinale dalla presa alla restituzione dell’acqua',
      //   C7_PlanimetriaScala: 'planimetria in scala ',
      //   C8_PlanimetriaGenerale: 'planimetria generale delle opere',
      //   C9_DocumentiDerivazione: 'piante, prospetti, sezioni e particolari di adeguata leggibilità ',
      //   C10_DocumentiPassaggioPesci: 'pianta, prospetti e sezioni per i pesci',
      //   C11_DispositiviMisurazione: 'progetto dei dispositivi di misurazione',
      //   C12_Urbanistica: 'inquadramento urbanistico',
      //   C13_DocumentazioneGraficaOpere: 'documentazione grafica con fotoinserimento delle opere progettate nel contesto di intervento',
      //   D_ProprietaTerreni: 'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni',
      //   E_ComputoMetrico: 'computo metrico estimativo e piano finanziario',
      //   F_SchedaFiltro: 'scheda filtro predisposta dall’Autorità di Bacino del Fiume Tevere'
      // },
      CartaDiIdentitaCambioTitolarita: 'Carta di identità',
      CambioTitolarita: 'Cambio di titolarità',
      ParereNatura2000: 'Parere Natura2000',
      CartaDiIdentitaVariazione: 'Documento di identità;',
      allegato: 'Allegato',
      CartaTecnicaRegionale: 'Carta Tecnica Regionale',
      EstrattoPlanimetria: 'Estratto Planimetria',
      RelazioneTecnicaGenerale: 'Relazione Tecnica Generale',
      PianteProspettiSezioni: 'Piante Prospetti Sezioni',
      RilascioConcessione: "Rilascio Concessione",
      RichiestaConcessione: 'Richiesta Concessione Firmata',
    },
    tipologiaAllegatiConcessioni: {
      common: {
        CartaDiIdentità: 'Copia di un documento di identità del richiedente',        
      },
      superficiali: {
        SchedaFiltro: "Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Rif. scheda C/grandi derivazioni, lettere A, I, J, N, O, S, V oppure Rif. scheda C/piccole derivazioni, lettere A, H, I, M, N, R, U",
        ElaboratiGrafici: "Rif. scheda C/grandi derivazioni, lettere B, C, D, E, G, H, Q oppure Rif. scheda C/piccole derivazioni, lettere B, C, D, E, F, G, P",
        CalcoloSommario: "Calcolo sommario della spesa e piano finanziario",
        DichiarazioneProprieta: "Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque",
        DichiarazioneConformitaPesca: "Progettazione conforme alla L.R. 15/2008 per la realizzazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva",
        DichiarazioneImpedimento: "Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, D.G.R. 925/2003)",
        DichiarazioneAssenso: "Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione",
      },
      superficialiIdroelettriche: {
        SchedaFiltro: "Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Rif. scheda D, lettera A",
        DisegniTecnici: "Rif. scheda D, lettere C.8, C.9, C.10, C.11 e disegni delle principali opere",
        Misure: "Rif. scheda D lettera B",
        ElaboratiCartografici: "Rif. scheda D lettere da C.1 a C.7, C.12, C.13",
        DichiarazioneProprieta: "Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, adduzione, presa e restituzione delle acque e la proprietà di tutti i terreni sui quali insisteranno le opere a servizio dell’impianto idroelettrico",
        ComputoMetrico: "Computo metrico estimativo e piano finanziario",
        DichiarazioneImpedimento: "Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, D.G.R. 925/2003)",
        DichiarazioneAssenso: "Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione", 
      },
      sorgenti: {
        SchedaFiltro: "Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Rif. scheda B lettere B, E",
        RelazioneGeologica: "Rif. scheda B lettera A",
        ElaboratiGrafici: "Rif. scheda B lettera A.10",
        DichiarazioneProprieta: "Rif. scheda B lettera C",
        AutorizzazioniEdilizie: "Rif. scheda B lettera G",
        DichiarazioneImpedimento: "Rif. scheda B lettera H",
        Idoneita: "Rif. scheda B lettera I",
        DichiarazioneConformitaPesca: "Progettazione conforme alla L.R. 15/2008 per la realizzazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva",
        DichiarazioneAssenso: "Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)",
      },
      sorgentiIdroelettriche: {
        SchedaFiltro: "Compilazione della scheda filtro dell’Autorità di Bacino Distrettuale",   
        VisuraCamerale: "Visura camerale" ,
        RelazioneTecnica: "Rif. scheda B lettere B, E e Rif. scheda D lettera A",
        RelazioneGeologica: "Rif. scheda B lettera A",
        DisegniTecnici: "Rif. scheda D, lettere C.8, C.9, C.10, C.11 e disegni delle principali opere",
        ElaboratiCartografici: "Rif. scheda B lettera A.10 e Rif. scheda D lettere da C.1 a C.7, C.12, C.13",
        Misure: "Rif. scheda D lettera B",
        ComputoMetrico: "Computo metrico estimativo e piano finanziario",
        DichiarazioneProprieta: "Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, adduzione, presa e restituzione delle acque e la proprietà di tutti i terreni sui quali insisteranno le opere a servizio dell’impianto idroelettrico",
        AutorizzazioniEdilizie: "Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione", 
        DichiarazioneAssenso: "Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)",  
      },
      sotterranee: {
        RelazioneGeologica: "NE: Rif. scheda A, lettere da A.1 ad A.6 <br> OPE: Rif. scheda A, lettere da A.1 ad A.7",
        RelazioneTecnica: "NE: Rif. scheda A, lettere da B.1 a B.11, F <br> OPE: Rif. scheda A, lettere da B.1 a B.11, F",
        ElaboratiGrafici: "NE: Rif. scheda A, lettere da C.1 a C.8, D <br> OPE: Rif. scheda A, lettere da C.1 a C.8, D",
        VisuraCamerale: "NE: Rif. scheda A, lettera E <br> OPE: Rif. scheda A, lettera E",
        AutorizzazioniEdilizie: "OPE: Rif. scheda A, lettera H",
        DichiarazioneImpedimento: "Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, D.G.R. 925/2003) <br> NE: Rif. scheda A, lettera H <br> OPE: Rif. scheda A, lettera I",
        DichiarazioneProprieta: "NE: Rif. scheda A, lettera I <br> OPE: Rif. scheda A, lettera J",
      },
      // SCHEDA_A_Pozzi: {
      //   A_RelazioneGeologicaIdrologica:
      //     'Lo studio descrive l’assetto idrogeologico e fornisce indicazioni sugli aspetti geologici e geomorfologici del territorio nel quale è prevista la captazione. Tale studio interessa un’area avente indicativamente il raggio di almeno un chilometro dall’opera di captazione e comunque di ampiezza tale da consentire le caratterizzazioni richieste. Nel caso di campo-pozzi le distanze sono calcolate a partire dal perimetro del campo stesso. Nella valutazione preliminare sull’estensione dell’area da investigare, sono comunque prioritariamente considerati gli eventuali limiti idrogeologici. Gli elaborati cartografici sono presentati a scala adeguata ai tematismi rappresentati. Lo studio dovrà contenere almeno quanto previsto ai punti successivi',
      //   A1_LineamentiGeomorfologici:
      //     'Lineamenti geomorfologici, geologici e d’uso del suolo, evidenziando l’eventuale presenza nell’intorno significativo di cave, discariche, di centri di pericolo in generale e di pozzi esistenti. Limitatamente ai prelievi per uso consumo umano di acque sotterranee erogate a terzi mediante impianti di acquedotto che rivestono carattere di pubblico interesse, nell’individuare il sito idoneo alla captazione, deve essere accertata nel raggio di almeno duecento metri dal punto prescelto l’assenza di centri di pericolo, come definiti dall’articolo 94 del D.Lgs. 152/2006; nella valutazione preliminare sull’estensione dell’area da investigare sono comunque considerati gli eventuali limiti idrogeologici. L’eventuale presenza di centri di pericolo nel raggio di duecento metri può essere ammessa solo quando nella zona la vulnerabilità intrinseca dell’acquifero da captare risulta, tramite approfonditi studi, bassa o molto bassa ed i centri di pericolo si trovano ai lati o a valle della direzione di flusso della falda e comunque all’esterno del probabile fronte di alimentazione del pozzo, valutato in funzione della portata massima estraibile',
      //   A2_StudioIdrologico: 'Lo studio idrogeologico generale sull’assetto litostratigrafico e sulle caratteristiche delle falde',
      //   A3_SchemaIdrologico:
      //     'Lo schema idrogeologico di massima del complesso idrogeologico oggetto di captazione, che permetta di individuare il comportamento idrodinamico dell’acquifero da captare ed il rapporto di quest’ultimo con altri livelli produttivi più o meno separati idraulicamente da esso',
      //   A4_CompatibilitaCaptazioni: 'Le considerazioni sulla compatibilità delle captazioni previste con il bilancio d’acqua e con l’assetto idrogeologico',
      //   A5_UsiRisorsa:
      //     'L’uso o gli usi cui la risorsa è destinata. Nel caso di richiesta di utilizzazione di acque di falde profonde per usi diversi da quello del consumo umano, lo studio deve altresì evidenziare la non disponibilità di risorse idriche alternative o la loro disponibilità in quantità non sufficiente e perciò necessitante di integrazione. La carenza di acqua di falda freatica o l’eventuale incompatibilità qualitativa della stessa, in particolare, deve essere dimostrata attraverso un approfondito studio idrogeologico ed idrochimico',
      //   A6_StratigrafiaTerreni: 'La stratigrafia dei terreni attraversati, il livello statico della falda',
      //   A7_ProvaPompaggio:
      //     'Le modalità di effettuazione ed i risultati di una prova di pompaggio (preferibilmente a gradini di portata), finalizzata alla determinazione della potenzialità dell’acquifero interessato e dei parametri caratterizzanti il comportamento idrodinamico del sistema messo in produzione, nonché finalizzata al dimensionamento della pompa da installare ed alla definizione del piano di utilizzo della risorsa (legge d’uso); la definizione del cono di emungimento e la verifica che lo stesso non interferisca con le altre opere di captazione esistenti ed autorizzate',
      //   A8_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   A81_InquadramentoCorografico:
      //     'L’inquadramento corografico IGM alla scala 1:25.000 dei punti o delle zone di potenziale captazione della risorsa e l’ubicazione degli stessi su CTR o ortofotocarte con evidenziati eventuali vincoli e zone vulnerabili (omettere se riportato nella relazione tecnica illustrativa di cui alla nota successiva)',
      //   A82_CartaGeologica: 'La carta geologica',
      //   A83_CartaIdrogeologica:
      //     'La carta idrogeologica con la ricostruzione della piezometria del sistema acquifero, con la definizione degli assi di deflusso principali e secondari. Qualora non fosse possibile effettuare le misure piezometriche degli acquiferi deve esserne adeguatamente specificato il motivo',
      //   A84_SezioniGelogiche:
      //     'Le sezioni geologiche ed idrogeologiche rappresentative della geometria degli acquiferi e delle unità litostratigrafiche impermeabili al contorno, possibilmente intersecanti i punti di approvvigionamento',
      //   B_RelazioneTecnica:
      //     'La relazione tecnica dovrà descrivere lo stato dell’opera di captazione, le sue caratteristiche costruttive, la dichiarazione d’uso della risorsa ed il fabbisogno idrico. La relazione tecnica deve almeno contenere',
      //   B1_FabbisognoAcqua:
      //     'Il fabbisogno quantitativo d’acqua, l’attività del richiedente, i cicli ed il tipo di produzione, la disponibilità attuale di risorse idriche, gli impianti di riciclo e di trattamento delle acque (qualora previsto), esistenti o da realizzare e i relativi tempi di attuazione; in particolare',
      //   B11_FabbisognoLordo:
      //     'Per l’uso irriguo deve essere dimostrato il fabbisogno lordo delle colture agrarie in relazione alle caratteristiche pedo-climatiche delle zone da irrigare, al tipo di coltura, all’estensione della superficie da irrigare rappresentata su mappa catastale o su Carta Tecnica Regionale, ai sistemi irrigui impiegati; il fabbisogno irriguo lordo e netto dovranno essere asseverati dal tecnico specialista',
      //   B12_ConsistenzaAllevamento:
      //     'Per l’uso igienico zootecnico si dovranno fornire informazioni sul tipo e la consistenza dell’allevamento, sul fabbisogno idrico pro capite e complessivo, sulla destinazione dei reflui. Tali informazioni devono essere asseverate dal tecnico specialista',
      //   B13_UsoIgienicoAgrituristico: 'Per l’uso igienico agrituristico si dovranno fornire informazioni sull’utilizzo effettivo della risorsa e sui quantitativi occorrenti per ciascuno scopo',
      //   B14_UsoIgienicoSanitario: 'Per l’uso igienico sanitario si dovranno fornire informazioni sulla consistenza dell’utenza e sulla dotazione pro capite',
      //   B15_UsoConsumoUmano:
      //     'Per l’uso consumo umano deve essere illustrata e giustificata la effettiva necessità quantitativa sulla base della popolazione servita; la scelta delle fonti di approvvigionamento deve risultare coerente con la pianificazione di settore; per quelle acque destinate alla preparazione di cibi e bevande, o utilizzate in un’impresa alimentare per la fabbricazione, il trattamento, la conservazione o l’immissione sul mercato di prodotti o di sostanze destinate al consumo umano si dovrà descrivere il ciclo produttivo e le fasi dello stesso per le quali è necessaria la risorsa',
      //   B16_UsoBeniServizi:
      //     'Per l’uso di produzione di beni e servizi devono essere specificate la natura del processo produttivo e le relative quantità d’acqua impiegata; deve essere altresì descritto il modo nel quale l’acqua viene impiegata nel processo produttivo, documentando l’utilizzo delle tecnologie che permettono di massimizzare il risparmio idrico',
      //   B17_AltriUsi: 'Per tutti gli altri usi, deve essere documentata la congruità dei volumi di prelievo richiesti in relazione agli utilizzi previsti',
      //   B2_DatiCatastaliRestituzione: 'I dati catastali relativi all’ubicazione dell’opera di presa e di eventuale restituzione',
      //   B3_DatiCatastaliIrriguo: 'I dati catastali relativi alla superficie da irrigare (nel caso di uso irriguo)',
      //   B4_Coordinate: 'Le coordinate Gauss Boaga',
      //   B5_Diametriperforazione: 'Il metodo e i diametri di perforazione',
      //   B6_Tubatura: 'Il tipo e le caratteristiche della tubatura di rivestimento',
      //   B7_DrenaggiFiltri: 'Il tipo di drenaggi e di filtri',
      //   B8_Fenestratura: 'Il tipo di fenestratura',
      //   B9_ProtezioneFalda: 'Il metodo di protezione e separazione della falda',
      //   B10_SviluppoPozzo: 'Il metodo di sviluppo del pozzo',
      //   B11_CaratteristichePompa: 'Le caratteristiche della pompa che si intende utilizzare',
      //   B12_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   B121_Cartografia: 'Inquadramento generale su base cartografica in scala 1:25.000 (omettere se riportato nella relazione geologica ed idrogeologica di cui alla nota precedente)',
      //   B122_Corografia:
      //     'Corografia su CTR in scala 1:10.000 con evidenziati eventuali vincoli e zone vulnerabili (particolare riferimento alla Tavola 45 PUT, aree parco, zone SIC,...) (omettere se riportato nella relazione geologica ed idrogeologica di cui alla nota precedente)',
      //   B123_PlanimetriaCatastale:
      //     'Planimetria catastale in scala 1:2000, su formato A3 (se la concessione è richiesta per uso irriguo dovrà essere delimitato ed evidenziato il comprensorio di irrigazione)',
      //   B124_PercorsiAcque:
      //     'Piante, prospetti, sezioni e particolari in scala non inferiore a 1:2.000 dei percorsi delle acque dal punto di prelievo all’utilizzo, dell’ubicazione degli eventuali serbatoi e dei punti di scarico, se previsto',
      //   B125_SchemaStratigrafico: 'Schema stratigrafico e costruttivo del pozzo',
      //   C_VisuraCatastale: 'Visura catastale dei terreni su cui ricadono i pozzi o Atto di proprietà',
      //   D_IscrizioneCameraCommercio: 'Dichiarazione sostitutiva del certificato di Iscrizione alla Camera di Commercio (se il richiedente è una società)',
      //   E_DocumentazioneFotografica: 'Documentazione fotografica referenziata (luogo di ubicazione delle opere di presa)',
      //   F_DocumentoRiconoscimento: 'Copia del documento di riconoscimento in corso di validità del richiedente',
      //   G_AttoAutorizzativo: "Copia dell'atto autorizzativo di realizzazione delle opere",
      //   H_DichiarazioneConsorziIrrigui:
      //     "Nel caso di uso irriguo dovrà essere prodotta una dichiarazione secondo cui l'azienda non è servita da consorzi irrigui, nè ha altre fonti di approvvigionamento idrico, così come previsto dall'art. 21 c 4 RD 1775/33 e come modificato dall'Art. 96 c 9 del D. Lgs. 152/06",
      //   I_AttoCompravendita:
      //     'Nel caso in cui i terreni, all’atto della domanda siano intestati a persona diversa dal richiedente è necessario presentare anche copia dell’atto di compravendita oppure del certificato di eseguita denuncia di successione, ovvero del documento attestante la presa d’atto del proprietario del terreno dell’intenzione del richiedente di ottenere la concessione alla derivazione di acque pubbliche',
      //   J_PagamentoCanone:
      //     "Eventuale copia del pagamento del canone annuo effettuato alla Regione per lo sfruttamento della risorsa in pendenza dell'iter di concessione, come previsto dall'Art. 264 c. 16 TU Governo del Territorio e Materie Correlate (L.R. 1/2015) e come modificato dall’Art. 51 della L.R. 13/2016",
      //   K_AdempimentiPrevisti:
      //     'Per quanto concerne infine gli adempimenti previsti dalle vigenti normative, quali quelli relativi alla procedibilità dell’istanza di concessione e quelli necessari al rilascio della concessione idrica (di cui all’ALLEGATO PER ADEMPIMENTI CON PagoUMBRIA), si fa presente che i medesimi verranno richiesti dalla Regione Umbria (ai richiedenti la concessione) con nota ufficiale Regionale – modalità pagamento con PagoUMBRIA.'
      // },
      // SCHEDA_B_Sorgenti: {
      //   A_RelazioneGeologica: 'Lo studio dovrà contenere almeno quanto previsto ai punti successivi',
      //   A1_InquadramentoArea:
      //     'Inquadramento dell’area, caratteri geologici, geomorfologici, ed idrogeologici con descrizione delle principali caratteristiche del bacino idrogeologico di alimentazione, dell’acquifero interessato dalla captazione e della zona di emergenza. Tale studio interessa un’area di ampiezza tale da consentire le caratterizzazioni richieste. Nella valutazione preliminare sull’estensione dell’area da investigare, sono comunque prioritariamente considerati gli eventuali limiti idrogeologici. Gli elaborati cartografici sono presentati a scala adeguata ai tematismi rappresentati',
      //   A2_IdentificazioneCartografica: 'Identificazione cartografica del bacino di alimentazione della sorgente',
      //   A3_ApportiMeteorici:
      //     'Stima degli apporti meteorici che il bacino di alimentazione della sorgente riceve nel tempo, condizioni generali di infiltrazione nel sottosuolo e modello concettuale di circolazione idrica sotterranea verso l’emergenza',
      //   A4_RegimePortata: 'Regime di portata della sorgente (possibilmente attraverso misure dirette da effettuare in un arco di tempo più lungo possibile)',
      //   A5_Portate: 'Portate naturali minima e massima della sorgente',
      //   A6_Quota: 'Quota del punto di emergenza',
      //   A7_TipoSorgente: 'Indicazione del tipo di sorgente in base alle caratteristiche di emergenza',
      //   A8_EmergenzeCaptazioni: 'Indicazione e localizzazione di altre emergenze e/o captazioni nell’area',
      //   A9_UtilizzoSuolo:
      //     'Descrizione dell’utilizzo del suolo nell’area di alimentazione della sorgente con individuazione di potenziali centri di pericolo ed delle cautele da adottarsi per prevenire possibili inquinamenti della falda. Limitatamente ai prelievi per uso potabile di acque sorgive erogate a terzi mediante impianti di acquedotto che rivestono carattere di pubblico interesse, lo studio idrogeologico è integrato dall’individuazione e localizzazione di centri di pericolo come definiti dall’articolo 94 del D.Lgs. 152/2006. Nella valutazione preliminare sull’estensione dell’area da investigare sono considerati gli eventuali limiti idrogeologici',
      //   A10_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   A101_Corografia:
      //     'Inquadramento corografico IGM alla scala 1:25.000 del o dei punti di captazione e l’ubicazione degli stessi su CTR o ortofotocarte con evidenziati eventuali vincoli e zone vulnerabili, con particolare riferimento alla Tavola 45 PUT, aree parco, zone SIC, SIR, ZPS ... (omettere se riportato nella relazione tecnica illustrativa di cui alla nota successiva)',
      //   A102_CartaGeologica:
      //     'Carta geologica (con ubicazione dell’opera) in scala 1:25.000 o superiore, di estensione sufficiente ad individuare le litologie presenti nell’area, i rapporti stratigrafici e gli elementi strutturali principali',
      //   A103_CartaIdrogeologica:
      //     'Carta idrogeologica (con ubicazione dell’opera) in scala 1:25.000 o superiore, di estensione sufficiente ad individuare i complessi presenti nell’area, l’acquifero investigato, il bacino di alimentazione della sorgente, le principali direttrici di flusso, i punti di emergenza ed eventuali altre captazioni presenti. La carta deve essere corredata di un profilo idrogeologico interpretativo',
      //   A104_SezioniGeologiche:
      //     'Sezioni geologiche ed idrogeologiche rappresentative della geometria degli acquiferi e delle unità litostratigrafiche impermeabili al contorno, delle quali almeno una intersechi il punto di emergenza considerato',
      //   B_RelazioneTenica:
      //     'La relazione tecnica dovrà descrivere le opere di captazione e le modalità di presa della sorgente, la dichiarazione d’uso della risorsa ed il fabbisogno idrico. La relazione tecnica deve almeno contenere',
      //   B1_Fabbisogno:
      //     'Il fabbisogno del quantitativo d’acqua, l’attività del richiedente, i cicli ed il tipo di produzione, la disponibilità attuale di risorse idriche, gli impianti di riciclo e di trattamento delle acque (qualora previsto), esistenti o da realizzare e i relativi tempi di attuazione',
      //   B2_QuantitativiRichiesti:
      //     'Quantitativi richiesti in concessione: portata media, portata massima, volume mensile e volume annuo, la durata di esercizio della captazione (continua o periodica). I quantitativi richiesti dovranno essere motivati, in particolare',
      //   B21_UsoIrriguoFabbisognoLordo:
      //     'Per l’uso irriguo deve essere dimostrato il fabbisogno lordo delle colture agrarie in relazione alle caratteristiche pedoclimatiche delle zone da irrigare, al tipo di coltura, all’estensione della superficie da irrigare rappresentata su mappa catastale o su Carta Tecnica Regionale, ai sistemi irrigui impiegati; il fabbisogno irriguo lordo e netto dovranno essere asseverati dal tecnico specialista',
      //   B22_UsoZootecnico:
      //     'Per l’uso zootecnico si dovranno fornire informazioni sul tipo e la consistenza dell’allevamento, sul fabbisogno idrico pro capite e complessivo, sulla destinazione dei reflui. Tali informazioni devono essere asseverate dal tecnico specialista',
      //   B23_UsoAgrituristico: 'Per l’uso agrituristico si dovranno fornire informazioni sull’utilizzo effettivo della risorsa e sui quantitativi occorrenti per ciascuno scopo',
      //   B24_UsoPotabile:
      //     'Per l’uso potabile deve essere illustrata e giustificata la effettiva necessità quantitativa sulla base della popolazione servita; la scelta delle fonti di approvvigionamento deve risultare coerente con la pianificazione di settore; per quelle acque destinate alla preparazione di cibi e bevande, o utilizzate in un’impresa alimentare per la fabbricazione, il trattamento, la conservazione o l’immissione sul mercato di prodotti o di sostanze destinate al consumo umano si dovrà descrivere il ciclo produttivo e le fasi dello stesso per le quali è necessaria la risorsa',
      //   B25_UsoBeniServizi:
      //     'Per l’uso di produzione di beni e servizi devono essere specificate la natura del processo produttivo e le relative quantità d’acqua impiegata; deve essere altresì descritto il modo nel quale l’acqua viene impiegata nel processo produttivo, documentando l’utilizzo delle tecnologie che permettono di massimizzare il risparmio idrico.',
      //   B26_AltriUsi: 'Per tutti gli altri usi, deve essere documentata la congruità dei volumi di prelievo richiesti in relazione agli utilizzi previsti',
      //   B27_Dispositivi: 'Descrizione dei dispositivi per la misurazione delle portate e dei volumi derivati ed eventualmente di quelli restituiti',
      //   B3_DatiCatastaliOperePresa: 'I dati catastali relativi all’ubicazione dell’opera di presa e di eventuale restituzione',
      //   B4_DatiCatastaliSup: 'dati catastali relativi alla superficie da irrigare (nel caso di uso irriguo)',
      //   B5_Coordinate: 'Le coordinate Gauss Boaga del punto di presa e di eventuale restituzione',
      //   B6_ElaboratiGrafici: 'Elaborati grafici comprendenti almeno',
      //   B61_InquadramentoCartografico: 'Inquadramento generale su base cartografica in scala 1:25.000 (omettere se riportato nella relazione geologica ed idrogeologica di cui alla nota precedente)',
      //   B62_Corografia:
      //     'Corografia su CTR in scala 1:10.000 con evidenziati eventuali vincoli e zone vulnerabili (particolare riferimento alla Tavola 45 PUT, aree parco, zone SIC, SIR, ZPS ...) (omettere se riportato nella relazione geologica ed idrogeologica di cui alla nota precedente)',
      //   B63_Planimetria: 'Planimetria catastale in scala 1:2000, su formato A3 (se la concessione è richiesta per uso irriguo dovrà essere delimitato ed evidenziato il comprensorio di irrigazione)',
      //   B64_Sezioni:
      //     'Piante, prospetti, sezioni e particolari in scala adeguata dell’opera di derivazione nel suo complesso, dal punto di prelievo, al punto di utilizzo, di eventuali serbatoi e dei punti di scarico, se previsti. Negli elaborati dovranno essere indicati eventuali attraversamenti (strade, corsi d’acqua ecc.) oltre che l’ubicazione dei dispositivi per la misurazione delle portate e dei volumi derivati e/o eventualmente restituiti',
      //   C_Proprieta:
      //     "Certificazione attestante la proprietà o la disponibilità dei terreni su cui ricadono: la sorgente, l'opera di derivazione nel suo complesso (sia esistente che da realizzare) ed i terreni da irrigare nel caso di uso irriguo. Nel caso in cui i terreni, all’atto della domanda siano intestati a persona diversa dal richiedente è necessario presentare anche copia dell’atto di compravendita oppure del certificato di eseguita denuncia di successione, ovvero del documento attestante la presa d’atto, da parte del proprietario del terreno, dell’intenzione del richiedente di ottenere la concessione alla derivazione di acque pubbliche",
      //   D_IscrizioneCameraCommercio: 'Dichiarazione sostitutiva del certificato di Iscrizione alla Camera di Commercio (se il richiedente è una società)',
      //   E_DocumentazioneFotografica: 'Documentazione fotografica referenziata delle opere esistenti, dei luoghi delle opere di progetto ed attrezzature (contatori ed altro)',
      //   F_DocumentoRiconoscimento: 'Copia del documento di riconoscimento in corso di validità del richiedente',
      //   G_AutorizzazioniEdilizie:
      //     'Autorizzazioni edilizie delle opere esistenti e/o da realizzare, connesse alla derivazione, o (per le opere esistenti) eventuale dichiarazione che “ai sensi e per gli effetti dell’art. 40 della L. 47/85 e art. 39 della L. 724/94, le opere edilizie presenti in istanza di concessione, sono state costruite in data anteriore al 1° settembre 1967, che per esso non ricorrevano i presupposti per il rilascio di concessione edilizia in sanatoria e che sull’immobile non sono stati effettuati successivamente ulteriori lavori, richiedenti Concessione edilizia, autorizzazione a costruire, anche in sanatoria”',
      //   H_Fabbisogno:
      //     'Sia per richieste ad uso potabile che irriguo dovrà essere prodotta una dichiarazione secondo cui il fabbisogno idrico per l’utilizzo richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/04, DGR 925/03)',
      //   I_AttivazioneASL:
      //     'Nel caso di richiesta per uso potabile dovrà essere prodotta la documentazione attestante l’attivazione, presso la ASL territorialmente competente, delle procedure necessarie all’ottenimento della certificazione di “idoneità all’uso potabile”'
      // },
      // SCHEDA_C_Superficiali: {
      //   grandi: {
      //     A_RelazioneParticolareggiata: `Relazione particolareggiata con speciale riguardo alla razionale utilizzazione del corpo idrico e del bacino idrografico (art. 9, R.D. 1285/1920 – art. 1 D.M. 16.12.1923)`,
      //     A1_ModalitaDervizione: `Modalità di derivazione e caratteristiche dell’opera di presa e dell’eventuale sistema di restituzione`,
      //     A2_OpereIdrauliche: `Descrizione delle principali opere idrauliche e dei dispositivi che limitano la portata da derivare e che restituiscono in alveo i quantitativi non utilizzati`,
      //     A3_CaratterizzazioneIdrologica: `Caratterizzazione idrologica del corpo idrico e calcolo della portata disponibile (le misure di portata saranno a cura del progettista)`,
      //     B_Corografia: 'Corografia (IGM in scala 1:25.000)',
      //     C_PianoGenerale: 'Piano Generale',
      //     D_Profili: 'Profili longitudinali e trasversali',
      //     E_Disegni: 'Disegni delle principali opere d’arte (in particolare dovranno essere rappresentate le opere di estrazione, di adduzione e di utilizzazione in scale adeguata)',
      //     F_CalcoloSommario: 'Calcolo sommario della spesa e piano finanziario',
      //     G_CartaTecnicaRegionale: 'Estratto della carta tecnica regionale (CTR 1:10.000) con la localizzazione dell’opera di presa e dell’eventuale opera di restituzione',
      //     H_Planimetria:
      //       'Estratto della planimetria catastale (1:2.000) con la localizzazione dell’opera di presa (nel caso di uso irriguo dovrà essere delimitato ed evidenziato il comprensorio irriguo)',
      //     I_Dispositivi:
      //       'Descrizione dei dispositivi di misurazione delle portate e dei volumi derivati e restituiti che verranno installati alla presa delle acque ed eventualmente alla restituzione delle stesse (qualora prevista) ai sensi del D.Lgs. 152/2006;',
      //     J_Fattibilità: 'Analisi di fattibilità di impianti utili a consentire il riciclo, il riuso ed il risparmio della risorsa idrica (per uso industriale);',
      //     K_FabbisognoIdrico: 'Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, DGR 925/03);',
      //     L_ProprietaTerreni:
      //       'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque;',
      //     M_AssensoProprietario:
      //       'Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque);',
      //     N_TipologiaPompe: 'Tipologia di pompe che verranno installate (qualora previste)',
      //     O_QuantitativoDeterminatoIrriguo:
      //       'Calcolo del quantitativo determinato in funzione dei tipi di coltura praticati, dei consumi medi e dei metodi di irrigazione effettuati nel rispetto di quanto disposto dall’art. 21 del R.D. 1775/1933 (nel caso di derivazione ad usoirriguo)',
      //     P_AutorizzazioniEdilizie:
      //       'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione, o eventuale dichiarazione che ai sensi dell’art. 40 della L. 47/85 e art. 39 della L. 724/94, le opere edilizie presenti in istanza di concessione, sono state costruite in data anteriore al 1° settembre 1967, che per esso non ricorrevano i presupposti per il rilascio di concessione edilizia in sanatoria e che sull’immobile non sono stati effettuati successivamente ulteriori lavori, richiedenti Concessione Edilizia, autorizzazione a costruire, anche in sanatoria',
      //     Q_StruttureProtezione: 'Progetto definitivo per le strutture a protezione della fauna ittica di cui alla L.R. n. 15 del 22.10.2008 in caso di sbarramenti su corpi idrici',
      //     R_ProgettazioneConforme: 'Progettazione conforme alla L.R. n. 15 del 22.10.2008 per la realizzazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva',
      //     S_DocumentazioneFotografica: 'Documentazione fotografica',
      //     T_AutoritaBacino: 'Compilazione della scheda filtro dell’Autorità di Bacino del Fiume Tevere',
      //     U_DichiarazioneSostitutivaCameraCommercio: 'Dichiarazione sostitutiva del certificato di Iscrizione alla camera di commercio (se il richiedente è una società)',
      //     V_DeflussoMinimoVitale:
      //       'Trasmissione del valore di Deflusso Minimo Vitale (DMV), individuato dal richiedente la concessione sulla base di quanto previsto dal Piano Regionale di Tutela delle Acque (PTA) approvato con Delibera del Consiglio Regionale dell’Umbria n. 357 del 01.12.2009'
      //   },
      //   piccole: {
      //     A_RelazioneParticolareggiata: `Relazione particolareggiata`,
      //     A1_ModalitaDervizione: `Modalità di derivazione e caratteristiche dell’opera di presa e dell’eventuale sistema di restituzione`,
      //     A2_OpereIdrauliche: `Descrizione delle principali opere idrauliche e dei dispositivi che limitano la portata da derivare e che restituiscono in alveo i quantitativi non utilizzati`,
      //     A3_CaratterizzazioneIdrologica: `Caratterizzazione idrologica del corpo idrico e calcolo della portata disponibile (le misure di portata saranno a cura del progettista)`,
      //     B_Corografia: 'Corografia (IGM in scala 1:25.000)',
      //     C_PianoTopografico: 'Piano topografico',
      //     D_Profili: 'Profili longitudinali e trasversali',
      //     E_DisegniOpereArte: 'Disegni delle principali opere d’arte (in particolare dovranno essere rappresentate le opere di estrazione, di adduzione e di utilizzazione in scale adeguata)',
      //     F_CartaTenicaRegionale: 'Estratto della carta tecnica regionale (CTR 1:10.000) con la localizzazione dell’opera di presa e dell’eventuale opera di restituzione',
      //     G_Planimetria:
      //       'Estratto della planimetria catastale (1:2.000) con la localizzazione dell’opera di presa (nel caso di uso irriguo dovrà essere delimitato ed evidenziato il comprensorio irriguo) e dell’eventuale opera di restituzione (qualora prevista)',
      //     H_Dispositivi:
      //       'Descrizione dei dispositivi di misurazione delle portate e dei volumi derivati e restituiti che verranno installati alla presa delle acque ed eventualmente alla restituzione delle stesse (qualora prevista) ai sensi del D.Lgs. 152/2006',
      //     I_Fattibilita: 'Analisi di fattibilità di impianti utili a consentire il riciclo, il riuso ed il risparmio della risorsa idrica (per uso industriale)',
      //     J_FabbisognoIdrico: 'Dichiarazione che il fabbisogno idrico per l’uso richiesto non è soddisfatto da reti destinate all’approvvigionamento per lo stesso uso (L.R. 33/2004, DGR 925/03)',
      //     K_ProprietaTerreni:
      //       'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque',
      //     L_AssensoProprietario:
      //       'Dichiarazione del proprietario del terreno attestante l’assenso a quanto richiesto (qualora il richiedente la concessione sia un soggetto diverso dal titolare dei terreni sui quali insisteranno le opere di raccolta, regolazione, presa e restituzione delle acque)',
      //     M_TipologiaPompe: 'Tipologia di pompe che verranno installate (qualora previste)',
      //     N_QuantitativoDeterminatoIrriguo:
      //       'Calcolo del quantitativo determinato in funzione dei tipi di coltura praticati, dei consumi medi e dei metodi di irrigazione effettuati nel rispetto di quanto disposto dall’art. 21 del R.D. 1775/1933 (nel caso di derivazione ad uso irriguo)',
      //     O_AutorizzazioneEdilizie:
      //       'Autorizzazioni edilizie relative alle opere realizzate e/o da realizzare connesse alla derivazione, o eventuale dichiarazione che ai sensi dell’art. 40 della L. 47/85 e art. 39 della L. 724/94, le opere edilizie presenti in istanza di concessione, sono state costruite in data anteriore al 1° settembre 1967, che per esso non ricorrevano i presupposti per il rilascio di concessione edilizia in sanatoria e che sull’immobile non sono stati effettuati successivamente ulteriori lavori, richiedenti Concessione Edilizia, autorizzazione a costruire, anche in sanatoria',
      //     P_StruttureProtezione: 'Progetto definitivo per le strutture a protezione della fauna ittica di cui alla L.R. n. 15 del 22.10.2008 in caso di sbarramenti su corpi idrici',
      //     Q_OpereIttiogeniche: 'Progettazione conforme alla L.R. n. 15 del 22.10.2008 per la realizzazione di opere concernenti il funzionamento di un impianto ittiogenico o di pesca sportiva',
      //     R_DocumentazioneFotografica: 'Documentazione fotografica',
      //     S_AutoritaBacino: 'Compilazione della scheda filtro dell’Autorità di Bacino del Fiume Tevere',
      //     T_DichiarazioneSostitutivaCameraCommercio: 'Dichiarazione sostitutiva del certificato di Iscrizione alla camera di commercio (se il richiedente è una società)',
      //     U_DeflussoMinimoVitale:
      //       'Trasmissione del valore di Deflusso Minimo Vitale (DMV), individuato dal richiedente la concessione sulla base di quanto previsto dal Piano Regionale di Tutela delle Acque (PTA) approvato con Delibera del Consiglio Regionale dell’Umbria n. 357 del 01.12.2009'
      //   }
      // },
      // SCHEDA_D_DerivazioneElettrica: {
      //   A_RelazioneTecnica: 'Relazione Tecnica che contenga almeno la descrizione di',
      //   A1_Tipologia:
      //     'tipologia (corso d’acqua, lago, invaso) denominazione del corpo idrico alimentatore e localizzazione con evidenziata l’eventuale appartenenza ad aree protette sotto la tutela di Parchi, o la presenza di vincoli di altra natura (piano regionale di tutela, piani territoriali di coordinamento provinciale, piani regolatori generali degli acquedotti, ecc.)',
      //   A2_UsoDestinazione: 'uso prevalente ed eventuali usi secondari a cui la risorsa è destinata',
      //   A3_Volumi:
      //     'volume totale annuo di acqua prelevata (mc/anno), distribuzione mensile e giornaliera del prelievo nell’anno solare, quantità media e massima di acqua da derivare espressa in moduli, l/sec., mc/h e i volumi restituiti',
      //   A4_ModalitaCaratteristiche: 'modalità di derivazione, caratteristiche dell’opera di presa e del sistema di restituzione',
      //   A5_Opere:
      //     'tipologia, forma, dimensioni delle opere esistenti (con documentazione fotografica e indicazione dei punti di ripresa) e delle opere di progetto e relative interferenze tra le stesse',
      //   A6_TipologiaTurbina: 'tipologia di turbina (o di turbine) e sue caratteristiche tecniche',
      //   A7_SaltoLegale:
      //     'salto legale H, definito come il dislivello fra i due peli morti dei canali (o della vasca) a monte e a valle del meccanismo motore, portata media annua derivata (Qmed), portata massima derivata, potenza di concessione P (P=Qmed*9,81*H), potenza massima ricavabile, potenza installata',
      //   A8_EnergiaProdotta: 'energia prodotta in Kwh nell’ “anno tipo”',
      //   A9_MeccanismiPortate: 'meccanismi di regolazione delle portate richieste',
      //   A10_DispositiviDinamici: 'tipologia e dimensioni dei dispositivi di ritenuta mobile, se presenti',
      //   A11_DispositiviDinamiciSicurezza:
      //     'logica di comando di dispositivi di ritenuta mobile, se presenti: ridondanza di funzionamento, dispositivi di sicurezza intrinseca, dispositivi di sicurezza a movimentazione manuale',
      //   A12_DispositiviDinamiciIdroelettrico: 'dispositivi per ridurre o eliminare gli effetti dinamici della cessazione istantanea di produzione idroelettrica: “colpo d’ariete” (se ricorre il caso)',
      //   A13_RischioIdraulico: 'valutazioni sull’eventuale modifica del rischio idraulico',
      //   A14_OpereAcquePubbliche:
      //     'compatibilità delle opere proposte rispetto al regime delle acque pubbliche, ai diritti di terzi, ad altre opere esistenti o a beni in genere con particolare riguardo alla stabilità di argini e sponde esistenti, con riferimento alle risultanze delle modellazioni idrauliche',
      //   A15_InformazioniGeognistiche:
      //     'informazioni geognostiche e geotecniche riguardanti il sito di realizzazione dell’opera di presa, dell’opera di restituzione, della centrale di produzione, e, qualora presenti, dei canali di adduzione, vasche di carico e condotte forzate',
      //   A16_BacinoIdrografico: 'bacino idrografico: dati dimensionali e sue caratteristiche morfologiche (con particolare riferimento ai piani vigenti e/o approvati)',
      //   A17_IdrologicoCorpoIdrico: 'aspetti idrologico/idraulici del corpo idrico',
      //   A18_IdrologicoArea: 'aspetti idrogeologici dell’area di realizzazione delle opere',
      //   A19_SistemiProtezione:
      //     'provvedimenti e sistemi di protezione dei locali quadri comando e dei locali quadri elettrici dalle inondazioni con riferimento ad eventi di piena con tempo di ritorno duecentennale',
      //   A20_AccessoPedonale: 'percorso per l’accesso pedonale ai locali di cui al punto precedente in situazioni di emergenza relative ad eventi di piena con tempo di ritorno duecentennale',
      //   A21_OperazioniSvaso: 'eventuali operazioni di svaso',
      //   A22_VascaCarico: 'vasca di carico se presente',
      //   A23_ProtezioneCanali: 'eventuali sistemi di protezione dei canali a tutela dell’incolumità di animali e persone',
      //   A24_ModalitaRestituzione: 'modalità di restituzione delle acque',
      //   A25_RealizzazioneViabilita: 'eventuale realizzazione di nuova viabilità permanente',
      //   A26_Dmv: 'DMVN= dmv rilasciato sulla base delle vigenti normative l/s',
      //   A27_RispettoDMV: 'sistema a garanzia del rispetto del DMV, specificando se di tipo strutturale, automatico o semiautomatico, manuale',
      //   A28_LunghezzaTratto: 'L1= lunghezza del tratto di alveo sotteso m',
      //   A29_LunghezzaRicurgito: 'LR =lunghezza rigurgito in regime di funzionamento nominale',
      //   A30_LunghezzaRicurgitoMassima: 'LRmax=lunghezza rigurgito in regime di funzionamento di massima portata derivabile',
      //   A31_VolumeInvasatoInvasi: 'Vi = volume invasato alla quota di regolazione in mc (per gli invasi)',
      //   A32_AltezzaSbarramento: 'hi = altezza dello sbarramento in m (per gli invasi',
      //   A33_VolumeInvasatoAcqua: 'V = volume invasato alla quota di regolazione in mc (per impianti ad acqua fluente)',
      //   A34_Compatibilita: 'compatibilità con allegati B e C del Regolamento Regionale n. 7/2011',
      //   B_Calcolazioni: 'Calcolazioni',
      //   B1_Bocche: 'dimensionamento delle bocche di presa e dei canali a giustificazione delle portate richieste',
      //   B2_CurvaPortate: 'curva di durata delle portate',
      //   B3_DimensioneTurbina: 'dimensionamento di massima della turbina/e',
      //   B4_VerificheIdrauliche:
      //     'verifiche idrauliche in regime di moto permanente estese a monte per tutto il tratto fluviale soggetto a rigurgito (da considerarsi esaurito alla sezione ove la differenza dei livelli idrici tra lo stato attuale e lo stato di progetto risulta inferiore all’approssimazione del codice di calcolo, adottato convenzionalmente, stabilita in 0.10m) nelle seguenti condizioni',
      //   B41_PortataMediaDerivata: 'esercizio alla portata media derivata, alla massima portata derivabile ed alla massima portata del corso d’acqua oltre la quale l’impianto deve essere fermato',
      //   B42_Piena50: 'evento di piena con tempo di ritorno cinquantennale',
      //   B43_Piena200: 'evento di piena con tempo di ritorno duecentennale',
      //   B44_Piena200Avaria: 'evento di piena con tempo di ritorno duecentennale in condizione di avaria dei dispositivi di ritenuta mobile qualora presenti',
      //   C_ElaboratiCartografici: 'Elaborati cartografici',
      //   C1_Corografia: 'corografia IGM 1:25000 con inquadramento',
      //   C2_CartaTecnica: 'carta Tecnica Regionale 1:10000 con inquadramento',
      //   C3_PlanimetriaCatastale: 'planimetria su base catastale 1:2000 con rappresentazione della presa, percorso e restituzione dell’acqua',
      //   C4_Sezioni:
      //     'profilo longitudinale e sezioni trasversali (estesi a monte fino al punto di rigurgito ed a valle fino al punto di restituzione) del corso d’acqua nello stato attuale e nello stato di progetto sia in condizioni di portata media derivata sia in condizioni di piena e corretto esercizio, sia in condizioni di piena ed avaria dei dispositivi mobili di ritenuta qualora presenti; i profili dovranno riportare le quote assolute riferite ai capisaldi PAI se presenti o a quelli IGM in scale opportunamente differenziate tra orizzontale e verticale',
      //   C5_Planimetrie:
      //     'planimetrie, profili longitudinali e sezioni trasversali in scale 1:500 e 1:200 dello stato attuale delle aree di intervento comprensivi delle opere esistenti dimensionate e quotate',
      //   C6_Profili: 'profilo longitudinale dalla presa alla restituzione dell’acqua in scale opportune con relative opere di progetto dimensionate e quotate',
      //   C7_PlanimetriaScala:
      //     'planimetria in scala non inferiore a 1:5000 delle aree soggette ad inondazione per eventi con tempo ritorno cinquantennale e duecentennale, dello stato attuale e dello stato di progetto nelle condizioni di corretto esercizio e di avaria dei dispositivi mobili di ritenuta',
      //   C8_PlanimetriaGenerale: 'planimetria generale delle opere in scala 1:500 comprensiva di tutti gli interventi progettuali descritti in relazione',
      //   C9_DocumentiDerivazione:
      //     'piante, prospetti, sezioni e particolari in scala 1:100 – 1:50 o comunque di adeguata leggibilità (con indicazione numerica delle dimensioni e delle quote altimetriche assolute) delle opere di presa, di adduzione, dei locali tecnici, delle opere di restituzione, dei congegni e meccanismi della derivazione',
      //   C10_DocumentiPassaggioPesci: 'pianta, prospetti e sezioni in scala 1:100 – 1:50 o comunque di adeguata leggibilità dell’eventuale passaggio per i pesci',
      //   C11_DispositiviMisurazione: 'progetto dei dispositivi di misurazione delle portate, dei volumi derivati e di quelli restituiti',
      //   C12_Urbanistica: 'inquadramento urbanistico, stralcio PRG, PTCP (con legende leggibili)',
      //   C13_DocumentazioneGraficaOpere: 'documentazione grafica con fotoinserimento delle opere progettate nel contesto di intervento',
      //   D_ProprietaTerreni:
      //     'Dichiarazione sostitutiva o certificazione attestante la proprietà dei terreni sui quali insisteranno le opere di raccolta, regolazione, adduzione, presa e restituzione delle acque e la proprietà dei terreni sui quali insisteranno tutte le opere a servizio dell’impianto idroelettrico',
      //   E_ComputoMetrico: 'computo metrico estimativo e piano finanziario',
      //   F_SchedaFiltro: 'scheda filtro predisposta dall’Autorità di Bacino del Fiume Tevere'
      // },
      CartaDiIdentitaCambioTitolarita: 'Carta di identità',
      CambioTitolarita: 'Cambio di titolarità',
      ParereNatura2000: 'Parere Natura2000',
      CartaDiIdentitaVariazione: 'Documento di identità;',
      allegato: 'Allegato',
      CartaTecnicaRegionale: 'Estratto della carta tecnica regionale (CTR 1:10.000) con la localizzazione della/e opera/e di variante',
      EstrattoPlanimetria: 'Estratto della planimetria catastale (1:2.000) con la localizzazione della/e opera/e di variante;',
      RelazioneTecnicaGenerale: 'Relazione tecnica generale descrittiva della/e opera/e di variante a firma di un tecnico iscritto al relativo Albo o Ordine Professionale;',
      PianteProspettiSezioni: 'Piante, prospetti, sezioni e particolari costruttivi in scala 1:100, o in scala adeguata, delle opere relative alla variante alla concessione, a firma di un tecnico abilitato;'      
      ,RilascioConcessione: "Rilascio Licenza Concessione",
      RichiestaConcessione: 'Richiesta Concessione Firmata',
    },
    puntoDiDerivazione: {
      fiume: 'Fiume',
      torrente: 'Torrente',
      fosso: 'Fosso',
      canale: 'Canale',
      lago: 'Lago',
      invaso: 'Invaso',
      sorgente: 'Sorgente',
      pozzo: 'Pozzo',
      diga: 'Diga',
      traversa: 'Traversa',
      acquedotto: 'Acquedotto',
      torbiera: 'Torbiera',
      bacinoartificiale: 'Bacino artificiale',
      stagnopalude: 'Stagno/Palude',
      acquifero: 'Acquifero',
      strumento: {
        tipo: {
          pompa: 'Pompa',
          tubo: 'Tubo (per caduta)',
          trattorebotte: 'Trattore/Botte',
          condotta: 'Condotta',
          canale: 'Canale',
          idrovora: 'Idrovora'
        },
        trasportabilita: {
          fisso: 'Fisso',
          semifisso: 'Semifisso',
          mobile: 'Mobile'
        }
      },
      corpiIdrici: {
        bacini: 'Bacini',
        corpiidricifluviali: 'Corpi idrici fluviali',
        corpiidricisuperficiali: 'Corpi idrici superficiali',
        corpiidricisotterranei: 'Corpi idrici sotterranei'
      }
    },
    colture: {
      nome: 'Nome',
      superficie: 'Superficie in ettari (ha)',
      percentuale: 'Percentuale (%)',
      add: 'Aggiungi una nuova coltura'
    },
    dichiarazioni: {
      sottoscrittoDichiara: `<strong>Il/La sottoscritto/a</strong>, consapevole delle sanzioni e delle conseguenze penali in caso di dichiarazioni mendaci o false attestazioni ai sensi del D.P.R. n° 445 del 28-12-2000, <strong>DICHIARA:</strong>`,
      rispettareDisposizioni:
        "Di rispettare tutte le disposizioni che saranno impartite anche successivamente al rilascio della licenza (limitazioni, sospensioni, revoca) e farle rispettare da eventuali addetti all'esercizio della pompa, nonchè di esercitare l'attingimento nel rispetto dell'art. 56 del R.D. 11 Dicembre 1933, n.1775 e s.m.e. i., di tutta la normativa vigente in materia e delle prescrizioni indicate nella licenza concessa;",
      zoneFrega: "Che in relazione alle zone di frega, osserverà il divieto di prelievo idrico per il periodo di due mesi dalla data dell'istituzione del vincolo;",
      prelievoRicade: 'Che il prelievo ricade',
      areaParco: 'in area parco;',
      areaSICSIRZPS: 'in area Natura 2000 (SIC-ZSC e ZPS);',
      corpoIdrico: 'Che il corpo idrico in oggetto',
      consumoUmano: 'fra quelli le cui acque sono riservate al consumo umano;',
      utilizzoAcqua: `Di utilizzare l'acqua esclusivamente per le finalità richieste in licenza e non la cederà a terzi. Tale disposizione non si applica ai soggetti deputati alla gestione del servizio irriguo descritto all'Allegato A - Punto 13. "Prescrizioni generali da rispettare" Lett. m della D.G.R. 1551/2016;`,
      flussoVitale: 'Di garantire il rispetto del minimo flusso vitale;',
      bollo: 'Che la marca da bollo, apposta alla presente domanda, è esclusivamente utilizzata per la presente richiesta di attingimento.',
      dichiarazioniMendaci: `Il sottoscritto dichiara che quanto sopra indicato corrisponde al vero, consapevole che le dichiarazioni mendaci, la falsità in atti e l'uso di atti falsi sono puniti ai sensi del Codice Penale e delle Leggi speciali in materia e comportano la decadenza dai benefici eventualmente conseguiti ai sensi dell’art. 75 del DPR 445/2000.`,
      sfruttamentoRisorsa: `Il sottoscritto è inoltre consapevole che la presentazione della richiesta di concessione non comporta di per sé il diritto allo 
      sfruttamento della risorsa, che potrà avvenire solo dopo il rilascio da parte dell’Autorità concedente del provvedimento 
      concessorio e che è facoltà della stessa la revoca della concessione in qualunque momento per accertata incompatibilità con 
      gli obiettivi di tutela dei corpi idrici superficiali e sotterranei interessati senza che ciò possa dar luogo alla corresponsione di 
      indennizzi da parte della Amministrazione concedente.`,
      modificheMedesime:
        'Il sottoscritto dichiara che salve le modifiche qui richieste, tutte le altre caratteristiche dell’utenza sono le medesime di quelle assentite con l’atto di concessione originario richiamato in premessa e nel relativo disciplinare di concessione;',
      canoniAnnuali:
        'Il sottoscritto dichiara di avere provveduto ad effettuare il versamento dei canoni annuali dovuti per l’utilizzo della risorsa, come indicato nell’atto di concessione citato in premessa;',
      ulterioriAllegati:
        'Il sottoscritto dichiara che provvederà ad inoltrare a codesta Amministrazione eventuali ulteriori elaborati tecnici e documenti che siano ritenuti necessari per lo svolgimento del procedimento istruttorio relativo alla variante sostanziale alla concessione di derivazione idrica richiesta.',
      ulterioriAllegatiNon:
        'Il sottoscritto dichiara che provvederà ad inoltrare a codesta Amministrazione eventuali ulteriori elaborati tecnici e documenti che siano ritenuti necessari per lo svolgimento del procedimento istruttorio relativo alla variante non sostanziale alla concessione di derivazione idrica richiesta.'
    },
    impegno: {
      misuratori: 'A mantenere in perfetta efficienza i misuratori volumetrici verificandone il funzionamento;',
      cartellino: "A mantenere esposto sulla pompa per tutto il periodo dell'attingimento il cartellino rilasciato dalla Regione Umbria con gli estremi identificativi della licenza;",
      volumi: "A comunicare i volumi idrici prelevati nell'anno in corso entro il 31 Dicembre;",
      attrezzature: 'A rimuovere, tutte le attrezzature, entro 30 giorni dal termine di scadenza della licenza con conseguente rimessa in pristino dei luoghi.'
    },
    informativa: {
      titolo: 'INFORMATIVA AI SENSI DELL.ART 13 DEL D. Lgs. 30-06-2003 n.196',
      sottotitolo: '(CODICE IN MATERIA DI PROTEZIONE DEI DATI PERSONALI)',
      body: `Ai sensi dell'articolo 13 del D. Lgs. 30-06-2003 n.196 "Codice in materia di protezione dei dati personali", in relazione ai dati personali che La riguardano e che formeranno oggetto di
      trattamento, La Informiamo di quanto segue: <br /><br />
      <strong>Finalità del trattamento dei dati:</strong> <br />Il trattamento è diretto all'espletamento da parte della Regione di funzioni istituzionali in virtù di compiti attribuitigli dalla
      legge e dai regolamenti; <br /><br /><strong>Modalità del trattamento:</strong> <br /><strong>a)</strong> é realizzato attraverso operazioni o complessi di operazioni, effettuati anche senza
      l'ausilio di strumenti elettronici, concernenti la raccolta, la registrazione, l'organizzazione, la conservazione, la consultazione, l'elaborazione, la selezione, l'estrazione, il raffronto,
      l'utilizzo, l'interconnessione, la comunicazione e la diffusione di dati indicate dall'articolo 4 del D.Lgs. 196/2003;<br /><br />
      <strong>b)</strong> é effettuato manualmente e/o con l'ausilio di mezzi informatici e telematici, secondo le prescrizioni inerenti la sicurezza previste dal titolo V del D. Lgs.
      n.196/2003;<br /><br />
      <strong>c)</strong> è svolto da personale con rapporto di lavoro o collaborazione con la Regione; <br />Conferimento dei dati: <br />
      è necessario come onere per l'interessato che voglia ottenere un determinato provvedimento/servizio;<br /><br /><strong>Rifiuto di conferire i dati:</strong> <br />l'eventuale rifiuto di
      conferire, da parte dell'interessato, i dati contenuti nella modulistica comporta l'impossibilità di evadere la pratica od ottenere l'effetto previsto dalla legge e/o da regolamenti;<br /><br />
      <strong>Comunicazione dei dati: </strong><br />dati acquisiti sono fatti oggetto di comunicazione o di diffusione, anche per via telematica, ad altri soggetti pubblici per lo svolgimento di
      funzioni istituzionali, nei casi e nel modi previsti dalla legge e/o dal regolamenti. Resta peraltro fermo quanto previsto dall'art. 50 del D.Lgs. 196/2003 sull'accesso ai documenti
      amministrativi contenenti dati personall come disciplinato dalla 1241/90 anche per quanto concerne i dati sensibili e gludiziari. <br /><br /><strong>Diritti dell'interessato:</strong>
      <br />L'articolo 7 del D. Lgs. 196/2003 conferisce all'interessato l'esercizio di specifici diritti, tra i quali, in particolare, quello di ottenere dal titolare la conferma dell'esistenza o
      meno di propi dati personali e ta loro comunicazione in forma intelligibile; di avere conoscenza dell'origine dei dati, nonché della logica applicata in caso di trattamento effettuato con
      l'ausilio di strumenti informatici e delle finalità su cui si basa il trattamento; di ottenere l'aggiornamento, la rettificazione, o, quando vi ha interesse, l'integrazione dei dati.
      <br /><br />Titolari e responsabili del trattamento: <br />Il titolare del trattamento dei dati è in Regione. <br /><br />Fonti di riferimento per il trattamento dei dati sensibili e
      giudiziari. <br /><br />
      Al sensi degli articoll 21 comma 1 e 22 commi 2 e 3 del D.Lgs 196/2003, la Regione effettua il trattamento dei dati sensibili e giudiziari indispensabili per svolgere attività istituzionali
      sulla base della normativa indicata nei singoli moduli.`,
      bodyusodiverso: `Ai sensi dell'art. 13 del Codice in materia di protezione dei dati personali (D.Lgs n. 196/2003), Le forniamo le seguenti informazioni: <br><br>1) I dati da Lei forniti verranno trattati per le
      finalità previste dal DPR 380/01 capo IV Provvedimenti per le costruzioni con particolari prescrizioni per le zone sismiche. <br><br>2) Il trattamento sarà effettuato con modalità manuali e
      informatizzate. <br><br>3) Il conferimento dei dati è obbligatorio ai sensi delle leggi citate e l'eventuale rifiuto di fornire tali dati comporterà la mancata prosecuzione del procedimento. <br><br>4) I
      dati saranno comunicati al Comune di competenza, ai sensi art. 94 del D.P.R. 380/01. Pagina 5 <br><br>5) Titolare del trattamento è la Regione dell'Umbria nella persona di Ing. Alberto Merrini
      dirigente responsabile del Servizio Geologico e sismico ai sensi del DPR 380/01. La struttura regionale è inoltre riportata al sito web: <a href="www.regione.umbria.it">www.regione.umbria.it</a> <br><br>6) In carica sono individuati
      nei dipendenti assegnati all’ufficio competente, di cui al p.to 6. In ogni momento potrà esercitare i Suoi diritti nei confronti del titolare del trattamento, ai sensi dell’art. 7 del D.lgs.
      196/2003`
    },
    riservatezza: {
      titolo: `TUTELA DELLA RISERVATEZZA – Presa d’atto`,
      body: `I dati personali raccolti saranno trattati, ai sensi Regolamento (UE) 2016/679 del Parlamento europeo e del Consiglio del 27 
      aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera 
      circolazione di tali dati e che abroga la direttiva 95/46/CE (regolamento generale sulla protezione dei dati), esclusivamente 
      nell’ambito della presente procedura.`
    }
  },

  tipoValidita: {
    nuova: 'Nuova',
    rinnovo: 'Rinnovo'
  }
};
